import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";

const Orders = (props) => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // setLoading(true);
        // setTimeout(() => {
        //     setLoading(false);
        // }, 1000);
    }, []);

    const [orders, setOrders] = useState([]);
  useEffect(() => {
    // axios.get("get-customer-orders/" + props.customer_id)
    //   .then(resp => {
    //     // console.log(resp.data);
    //     // setLoading(false);
    //     setOrders(resp.data);
    //   }).catch(err => {
    //     console.log(err);
    //   });

      setOrders(props.orders);
  }, []);

  // console.log(orders);
    return (
        <div>
            {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
            <div className="tab-content account dashboard-content pl-lg-50">
            <div className="tab-pane fade active show" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
              <div className="card">
                <div className="card">
                  <div className="card-header ps-0">
                    <h5 className="mb-0">Your Orders</h5>
                  </div>
                  <div className="">
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            {/* <th>Order #</th> */}
                            <th>Order Number</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Total Price</th>
                            {/* <th>Shipping Address</th> */}
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                        {!orders[0] ?
                            (
                              <tr>
                                <td className="text-center" colSpan="5">
                                  No orders found!
                                </td>
                              </tr>
                            )
                            :
                            (
                              orders.map((order, key) => {
                                return(
                                <tr key={key}>
                                  {/* <td className="">
                                    {order.id}
                                  </td> */}
                                  <td data-title="Order Number" className="">
                                    {order.order_number}
                                  </td>
                                  <td data-title="Date Time" className="">
                                    {order.order_date_time.slice(0, 10)}&nbsp;
                                    {order.order_date_time.slice(11, 19)}
                                  </td>
                                  <td data-title="Status" className="" style={{fontSize:'20px', color:'orange'}}>
                                    {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
                                  </td>
                                  <td data-title="Price" className="">
                                    {order.pay_amount}
                                  </td>
                                  {/* <td className="">
                                    {order.shipping_address}
                                  </td> */}
                                  <td data-title="Action">
                                    <Link to={'/customer/order/'+order.id} className="btn">
                                    View
                                    </Link>
                                  </td>
                                </tr>
                                )
                              }
                              )
                            )
                          }
                          {/* {orders && ()
                          } */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
    );
}

export default Orders;