import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../layouts/Layout";
import Parser from 'html-react-parser';
import Swal from "sweetalert2";
import LoadingBar from "react-top-loading-bar";
import ReCAPTCHA from "react-google-recaptcha";

const ForgetPassword = () => {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const ref = useRef();
  useEffect(() => {
    ref.current.continuousStart();
    setTimeout(() => {
      ref.current.complete();
    }, 200);

    window.scrollTo(0, 0)
  }, []);
  useEffect(() => {
    document.title = "Ultimate Organic Life | Forgot Password";
  }, []);

  const [error, setError] = useState();
  let [userInput, setUserInput] = useState("");

  const key = '6LdkPlkmAAAAADFerYJsNhsjrfx1JzgH632vwuMT';
  const [captchaIsDone, setCaptchaIsDone] = useState(false);
  let [captchaError, setCaptchaError] = useState("");

  const sendOTP = () => {
    if (!userInput) {
      ref.current.complete();
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Phone or Email Required!',
        // showConfirmButton: false,
        // timer: 2000
      });
    }
    else if (!captchaIsDone) {
      setCaptchaError("Please complete the captcha first");
    }
    else {
      setCaptchaError("");
      ref.current.continuousStart();
      axios
        .post("forget-customer-password", { user_input: userInput })
        .then(function (resp) {

          ref.current.complete();
          // console.log(resp.data);
          if (resp.data.success) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: resp.data.message,
              // showConfirmButton: false,
              // timer: 2000
            }).then(function (response) {
              if (response.isConfirmed) {
                navigate("/verify-otp-set-new-password/" + resp.data.data.customer_id);
                window.location.reload(false);
              }
            });
          }
          else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Invalid Input Or Phone or Email Not Registered',
              // showConfirmButton: false,
              // timer: 2000
            });
            setError(resp.data.message);
          }
        })
        .catch((err) => {

          ref.current.complete();
          console.log(err);
          setError(err.response.data.errors);
        });
    }
  }

  return (
    <div>
      <LoadingBar
        color='#0098b8'
        ref={ref}
      // progress={progress}
      // onLoaderFinished={() => setProgress(0)}
      />
      <Layout>
        <div className="page-header breadcrumb-wrap">
          <div className="breadcrumb">
            <Link to="/" itemProp="item" title="Home">
              Home
            </Link>
            <span></span> Forgot Password
            <span></span>
          </div>
        </div>
        <br />
        <div className="row justify-content-center">
          <div className="col-6 pt-1 pb-4">
            <div class="login_wrap widget-taber-content p-30 background-white border-radius-10">
              <div class="padding_eight_all bg-white">
                <div class="heading_s1 mb-20">
                  <h3 class="mb-30">Reset Password</h3>
                </div>
                <div class="form__content">
                  <div class="form-group">
                    <label for="txt-email" class="required">Phone Or Email</label>
                    <input name="email" id="txt-email" type="email" value={userInput} onChange={(e) => { setUserInput(e.target.value) }} placeholder="Please enter your email address" class="form-control" required />
                  </div>
                </div>
                <div className="form-group">
                  <ReCAPTCHA
                    sitekey={key}
                    onChange={(value)=>{ 
                    if(value){
                      setCaptchaIsDone(true);
                    }
                    else{
                      setCaptchaIsDone(false);
                    }
                  }}
                  />
                  <span className="text-danger">{captchaError}</span>
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-fill-out btn-block hover-up" onClick={() => { sendOTP(); }}>Send OTP</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </Layout>
    </div>
  );
};

export default ForgetPassword;
