// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product_skelton_image{
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    /* padding: 2rem; */
    height: 200px;
    width: 95% !important;
}

.product_skelton_title{
    height: 2rem;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
    width: 90% !important;
}

.product_skelton_price{
    top: 14%;
    height: 1.3rem !important;
    margin-bottom: 1rem;
    width: 80% !important;
}

.product_skelton_add_btn{
    margin-bottom: 1rem;
    height: 40px;
    width: 65% !important;
}

@media screen and (max-width: 480px) {
    .product_skelton_image{
        width: 91% !important;
    }
    
  }

  @media screen and (max-width: 768px) {
    .product_skelton_image{
        width: 95.4% !important;
    }
    
  }`, "",{"version":3,"sources":["webpack://./src/assets/Css/product_skelton.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,oBAAoB;IACpB,mBAAmB;IACnB,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,QAAQ;IACR,yBAAyB;IACzB,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI;QACI,qBAAqB;IACzB;;EAEF;;EAEA;IACE;QACI,uBAAuB;IAC3B;;EAEF","sourcesContent":[".product_skelton_image{\n    margin-top: 0.5rem;\n    margin-left: 0.5rem;\n    margin-right: 0.5rem;\n    /* padding: 2rem; */\n    height: 200px;\n    width: 95% !important;\n}\n\n.product_skelton_title{\n    height: 2rem;\n    font-size: 16px;\n    font-weight: 700;\n    margin-bottom: 5px;\n    width: 90% !important;\n}\n\n.product_skelton_price{\n    top: 14%;\n    height: 1.3rem !important;\n    margin-bottom: 1rem;\n    width: 80% !important;\n}\n\n.product_skelton_add_btn{\n    margin-bottom: 1rem;\n    height: 40px;\n    width: 65% !important;\n}\n\n@media screen and (max-width: 480px) {\n    .product_skelton_image{\n        width: 91% !important;\n    }\n    \n  }\n\n  @media screen and (max-width: 768px) {\n    .product_skelton_image{\n        width: 95.4% !important;\n    }\n    \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
