// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(231, 242, 244, 0.834);
    z-index: 9999;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #21b1e6 transparent #21b1e6 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
    z-index: 9999;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/Css/loadingAnimation.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,MAAM;IACN,OAAO;IACP,eAAe;IACf,sCAAsC;IACtC,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,qDAAqD;IACrD,kBAAkB;IAClB,yCAAyC;IACzC,aAAa;AACjB;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".loader-container {\n    width: 100%;\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    top: 0;\n    left: 0;\n    position: fixed;\n    background: rgba(231, 242, 244, 0.834);\n    z-index: 9999;\n}\n\n.spinner {\n    width: 64px;\n    height: 64px;\n    border: 8px solid;\n    border-color: #21b1e6 transparent #21b1e6 transparent;\n    border-radius: 50%;\n    animation: spin-anim 1.2s linear infinite;\n    z-index: 9999;\n}\n\n@keyframes spin-anim {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
