import React from "react";
import axios from "axios";
// import { Link } from "react-router-dom";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Font } from "@react-pdf/renderer";
import Nikosh from '../assets/fonts/Nikosh.ttf';
import Mydocument from "../components/pdf/pdfTemplate";
import MyDocument from "../components/pdf/pdfTemplate";
// Font.register({ family: 'Nikosh', src: '../assets/fonts/Nikosh.ttf', fontStyle: 'normal', fontWeight: 'normal' });
Font.register({
  family: 'Nikosh',
  fonts: [
    {
      src: Nikosh,
    },
  ],
});
// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
  },
  section: {
    margin: 10,
    padding: 10
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  text: {
    fontFamily: 'Nikosh',
  },
});

// Create Document Component
function GeneratePDF() {
  return (
    <>
     <PDFViewer style={styles.viewer}>
      <MyDocument />
      </PDFViewer>
      
    </>
  );
}
export default GeneratePDF;