import axios from "axios";
import React, { memo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import Layout from "../layouts/Layout";

const Faq = () => {

  const [toggle, setToggle] = useState();

  const setToggleClick = (key) => {

    setToggle(key);

  };

  const [faqs, setFaqs] = useState(null);
  let loadCheck = 0;
  useEffect(() => {
    document.title = "Ultimate Organic Life | Faq";
    window.scrollTo(0, 0);

    if (!faqs && loadCheck === 0) {
      loadCheck = 1;
      axios.get("get-faqs")
        .then(resp => {
          // console.log(resp.data);
          setFaqs(resp.data.faqs);
        }).catch(err => {
          console.log(err);
        });
    }

  }, [faqs]);
  // console.log(loadCheck);
  return (
    <div>
      <Layout>
        <div className="page-header breadcrumb-wrap">
          <div className="breadcrumb" itemScope itemType="http://schema.org/BreadcrumbList">
            <a href="/" itemProp="item" title="Home">
              Home
              <meta itemProp="name" content="Home" />
              <meta itemProp="position" content="1" />
            </a>
            <span></span> Faq
            <meta itemProp="name" content="Account information" />
            <meta itemProp="position" content="2" />
          </div>
        </div>
        <section className="mt-10 mb-60">
          <div className="faqs-list">
            {faqs ?
              (faqs.map((section, index) => {
                return <div key={index} className="m-2">
                  <h4>{section.name}</h4>{" "}
                  <div id="faq-accordion-0" className="accordion">
                    {section &&
                      section.faqs.map((faq, index) => {
                        return (
                          <div key={index} className="card">
                            <div id="heading-faq-0-0" className="card-header">
                              <h2 className="mb-0">
                                {toggle === faq.question ? (
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse-faq-0-0"
                                    aria-expanded="true"
                                    aria-controls="collapse-faq-0-0"
                                    className={"btn btn-link btn-block text-left"}
                                    onClick={() => setToggleClick(null)}
                                  >
                                    {faq.question}
                                  </button>
                                ) :
                                  (
                                    <button
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapse-faq-0-0"
                                      aria-expanded="true"
                                      aria-controls="collapse-faq-0-0"
                                      className={"btn btn-link btn-block text-left collapsed"}
                                      onClick={() => setToggleClick(faq.question)}
                                    >
                                      {faq.question}
                                    </button>
                                  )
                                }
                              </h2>
                            </div>{" "}
                            {toggle === faq.question && (
                              <div id="collapse-faq-0-0" aria-labelledby="heading-faq-0-0" data-parent="#faq-accordion-0" className="collapse  show ">
                                <div className="card-body">
                                  {faq.answer}
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>{" "}
                </div>;
              }))
              : (
                <div className="m-2">
                  <h3 className="pb-2"><Skeleton inline={true} width={'22%'} /></h3>{" "}
                  <div id="faq-accordion-0" className="accordion">
                    <div className="card">
                      <div id="heading-faq-0-0" className="card-header">
                        <h2 className="mb-0">
                          <button
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-faq-0-0"
                            aria-expanded="true"
                            aria-controls="collapse-faq-0-0"
                            className={"btn btn-link btn-block text-left collapsed"}
                          >

                            <Skeleton inline={true} width={'75%'} style={{ fontSize: '16px' }} />
                          </button>
                        </h2>
                      </div>{" "}
                    </div>
                  </div>
                  <div id="faq-accordion-0" className="accordion">
                    <div className="card">
                      <div id="heading-faq-0-0" className="card-header">
                        <h2 className="mb-0">
                          <button
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-faq-0-0"
                            aria-expanded="true"
                            aria-controls="collapse-faq-0-0"
                            className={"btn btn-link btn-block text-left collapsed"}
                          >

                            <Skeleton inline={true} width={'75%'} style={{ fontSize: '16px' }} />
                          </button>
                        </h2>
                      </div>{" "}
                    </div>
                  </div>
                  <div id="faq-accordion-0" className="accordion">
                    <div className="card">
                      <div id="heading-faq-0-0" className="card-header">
                        <h2 className="mb-0">
                          <button
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-faq-0-0"
                            aria-expanded="true"
                            aria-controls="collapse-faq-0-0"
                            className={"btn btn-link btn-block text-left collapsed"}
                          >

                            <Skeleton inline={true} width={'75%'} style={{ fontSize: '16px' }} />
                          </button>
                        </h2>
                      </div>{" "}
                    </div>
                  </div>
                  <br />
                  <h3 className="pb-2"><Skeleton inline={true} width={'22%'} /></h3>{" "}
                  <div id="faq-accordion-0" className="accordion">
                    <div className="card">
                      <div id="heading-faq-0-0" className="card-header">
                        <h2 className="mb-0">
                          <button
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-faq-0-0"
                            aria-expanded="true"
                            aria-controls="collapse-faq-0-0"
                            className={"btn btn-link btn-block text-left collapsed"}
                          >

                            <Skeleton inline={true} width={'75%'} style={{ fontSize: '16px' }} />
                          </button>
                        </h2>
                      </div>{" "}
                    </div>
                  </div>
                  <div id="faq-accordion-0" className="accordion">
                    <div className="card">
                      <div id="heading-faq-0-0" className="card-header">
                        <h2 className="mb-0">
                          <button
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-faq-0-0"
                            aria-expanded="true"
                            aria-controls="collapse-faq-0-0"
                            className={"btn btn-link btn-block text-left collapsed"}
                          >

                            <Skeleton inline={true} width={'75%'} style={{ fontSize: '16px' }} />
                          </button>
                        </h2>
                      </div>{" "}
                    </div>
                  </div>
                  <br />
                  <h3 className="pb-2"><Skeleton inline={true} width={'22%'} /></h3>{" "}
                  <div id="faq-accordion-0" className="accordion">
                    <div className="card">
                      <div id="heading-faq-0-0" className="card-header">
                        <h2 className="mb-0">
                          <button
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-faq-0-0"
                            aria-expanded="true"
                            aria-controls="collapse-faq-0-0"
                            className={"btn btn-link btn-block text-left collapsed"}
                          >

                            <Skeleton inline={true} width={'75%'} style={{ fontSize: '16px' }} />
                          </button>
                        </h2>
                      </div>{" "}
                    </div>
                  </div>
                  <div id="faq-accordion-0" className="accordion">
                    <div className="card">
                      <div id="heading-faq-0-0" className="card-header">
                        <h2 className="mb-0">
                          <button
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-faq-0-0"
                            aria-expanded="true"
                            aria-controls="collapse-faq-0-0"
                            className={"btn btn-link btn-block text-left collapsed"}
                          >

                            <Skeleton inline={true} width={'75%'} style={{ fontSize: '16px' }} />
                          </button>
                        </h2>
                      </div>{" "}
                    </div>
                  </div>
                  <div id="faq-accordion-0" className="accordion">
                    <div className="card">
                      <div id="heading-faq-0-0" className="card-header">
                        <h2 className="mb-0">
                          <button
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-faq-0-0"
                            aria-expanded="true"
                            aria-controls="collapse-faq-0-0"
                            className={"btn btn-link btn-block text-left collapsed"}
                          >

                            <Skeleton inline={true} width={'75%'} style={{ fontSize: '16px' }} />
                          </button>
                        </h2>
                      </div>{" "}
                    </div>
                  </div>
                  <br />
                  <h3 className="pb-2"><Skeleton inline={true} width={'22%'} /></h3>{" "}
                  <div id="faq-accordion-0" className="accordion">
                    <div className="card">
                      <div id="heading-faq-0-0" className="card-header">
                        <h2 className="mb-0">
                          <button
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-faq-0-0"
                            aria-expanded="true"
                            aria-controls="collapse-faq-0-0"
                            className={"btn btn-link btn-block text-left collapsed"}
                          >

                            <Skeleton inline={true} width={'75%'} style={{ fontSize: '16px' }} />
                          </button>
                        </h2>
                      </div>{" "}
                    </div>
                  </div>
                  <div id="faq-accordion-0" className="accordion">
                    <div className="card">
                      <div id="heading-faq-0-0" className="card-header">
                        <h2 className="mb-0">
                          <button
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-faq-0-0"
                            aria-expanded="true"
                            aria-controls="collapse-faq-0-0"
                            className={"btn btn-link btn-block text-left collapsed"}
                          >

                            <Skeleton inline={true} width={'75%'} style={{ fontSize: '16px' }} />
                          </button>
                        </h2>
                      </div>{" "}
                    </div>
                  </div>
                  <div id="faq-accordion-0" className="accordion">
                    <div className="card">
                      <div id="heading-faq-0-0" className="card-header">
                        <h2 className="mb-0">
                          <button
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-faq-0-0"
                            aria-expanded="true"
                            aria-controls="collapse-faq-0-0"
                            className={"btn btn-link btn-block text-left collapsed"}
                          >

                            <Skeleton inline={true} width={'75%'} style={{ fontSize: '16px' }} />
                          </button>
                        </h2>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </section>
      </Layout>
    </div>
  );
};

export default memo(Faq);
