import { createSlice } from "@reduxjs/toolkit";

export const BestSellerProductDataSlice = createSlice({
  name: "BestSellerProductData",
  initialState: {
    BestSellerProductData: null,
  },
  reducers: {
    setBestSellerProductData: (state, action) => {
      state.BestSellerProductData = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setBestSellerProductData } = BestSellerProductDataSlice.actions;

export default BestSellerProductDataSlice.reducer;

