import { createSlice } from "@reduxjs/toolkit";

export const FooterBannerSlice = createSlice({
  name: "FooterBanner",
  initialState: {
    FooterBanner: [],
  },
  reducers: {
    setFooterBanner: (state, action) => {
      state.FooterBanner = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setFooterBanner } = FooterBannerSlice.actions;

export default FooterBannerSlice.reducer;

