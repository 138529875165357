import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Layout from "../layouts/Layout";
import Parser from 'html-react-parser';
import Skeleton from "react-loading-skeleton";
import '../assets/Css/blog.css';
import SEO from "../components/Seo";

const BlogView = () => {
  const [loading, setLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 500);
  // }, []);
  useEffect(() => {
    document.title = "Ultimate Organic Life | Blog";
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const { slug } = useParams();
  const [blog, setBlog] = useState();
  useEffect(() => {
    axios.get("blog/" + slug)
      .then(resp => {
        // console.log(resp.data);
        setBlog(resp.data.data);
      });
  }, []);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://ultimateorganiclife.com/"
    },{
      "@type": "ListItem",
      "position": 2,
      "name": "Blogs",
      "item": "https://ultimateorganiclife.com/blogs"
    },{
      "@type": "ListItem",
      "position": 3,
      "name": blog && (blog.title)
    }]
  };

  return (
    <div>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      {blog && (
        <SEO title={blog.title ? 'Ultimate Organic Life | '+blog.title : 'Ultimate Organic Life | Blog View'} meta_title={blog.meta_title ? blog.meta_title : ""} 
        meta_description={blog.meta_description ? blog.meta_description : ""} keywords={blog.meta_keywords ? blog.meta_keywords : ""}
        name={'Ultimate Organic Life'} type={'ecommerce.blog'} meta_image={blog.image ? blog.image : "Blog Image"} />
      )}
      <Layout>
        <div className="page-header breadcrumb-wrap">
          <div className="breadcrumb">
            <Link to="/" itemProp="item" title="Home">
              Home
            </Link>
            <span></span>
            <Link to="/blogs" itemProp="item" title="Blogs">
              Blogs
              <meta itemProp="name" content="Blogs" />
              <meta itemProp="position" content="2" />
            </Link>
            <span></span> {blog && blog.title}
            <span></span>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-9 pt-1 pb-4">
            {/* {blog && ( */}
              <div className="single-page">
                <div className="single-header style-2">
                  <div className="row">
                    <div className="col-lg-12 m-auto p-0">
                      {/* <h6 className="mb-10"><a href="#">{blog ? blog.category.name : <Skeleton inline={true} width={'12%'} />}</a></h6> */}
                      <h2 className="mb-10">{blog ? blog.title : <Skeleton inline={true} width={'40%'} />}</h2>
                      <div className="single-header-meta">
                        <div className="entry-meta meta-1 font-xs mt-15 mb-15">
                          {/* <span className="author-avatar">
                            <img src="data:image/png;base64,5CYII=" alt="System Admin" className="img-circle" />
                          </span> */}
                          <span className="post-by">{blog ? 'By '+ blog.author : <Skeleton inline={true} width={130} />}</span>
                          <span className="post-on has-dot">{blog ? blog.created_at.slice(0,10) : <Skeleton inline={true} width={80} />}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <center>
                <figure className="single-thumbnail">
                  {blog && <img src={blog.image} alt={blog.title} onLoad={()=>{setImageLoaded(true);}} />}  {!imageLoaded && <Skeleton className="blog_image_skelton" inline={true} height={640} />}
                </figure>
                </center>
                <div className="single-content">
                  <div className="row">
                    <div className="col-lg-12 m-auto p-0 blog_description">
                    {blog ? Parser(blog.description) : <Skeleton count={13} />}
                    </div>
                  </div>
                </div>
              </div>
              {/* )} */}

          </div>
          <div className="col-9">
          <div className="single-social-share clearfix mt-50 mb-15">
                        <p className="mb-4 mt-4 font-sm">
                          <i className="fi-rs-share me-2"></i>
                          <span className="d-inline-block">Share this</span>
                          </p>
                          <div className="mobile-social-icon wow fadeIn mb-sm-5 mb-md-0 animated animated" style={{visibility: "visible", justifyContent: "flex-start"}}>
                            <a href={"https://www.facebook.com/sharer/sharer.php?u="+window.location.href} target="_blank" className="facebook">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path>
                              <path fill="#ffffff" d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z"></path>
                              </svg>
                              </a>
                              <a href={"https://twitter.com/intent/tweet?url="+window.location.href+"&text="+(blog && blog.meta_description ? blog.meta_description : "Description")} target="_blank" className="twitter">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path>
                                <path fill="#ffffff" d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z"></path>
                                </svg></a>
                                <a href={"https://www.linkedin.com/shareArticle?mini=true&url="+window.location.href+"&summary="+(blog && blog.meta_description ? blog.meta_description : "Meta Description")} target="_blank" className="linkedin"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="#ffffff" d="M18.335 18.339H15.67v-4.177c0-.996-.02-2.278-1.39-2.278-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387 2.7 0 3.2 1.778 3.2 4.091v4.715zM7.003 8.575a1.546 1.546 0 0 1-1.548-1.549 1.548 1.548 0 1 1 1.547 1.549zm1.336 9.764H5.666V9.75H8.34v8.589zM19.67 3H4.329C3.593 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.338C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3h.003z"></path>
                                </svg></a>
                                </div>
                      </div>
          </div>
        </div>

      </Layout>
    </div>
  );
};

export default BlogView;
