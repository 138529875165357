import { createSlice } from "@reduxjs/toolkit";

export const FlashNewsDataSlice = createSlice({
  name: "FlashNewsData",
  initialState: {
    FlashNewsData: null,
  },
  reducers: {
    setFlashNewsData: (state, action) => {
      state.FlashNewsData = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setFlashNewsData } = FlashNewsDataSlice.actions;

export default FlashNewsDataSlice.reducer;

