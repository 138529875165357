import React from "react";
import { Helmet } from "react-helmet";
const SEO = ({title, meta_title, meta_description, keywords, name, type, meta_image}) => {
  
    return (
        <Helmet title={title}
        meta={[
            {name: 'title', content: meta_title},
            {name: 'description', content: meta_description},
            {name: 'keywords', content: keywords},
            {property: 'og:title', content: meta_title},
            {property: 'og:image', content: meta_image},
            {property: 'og:description', content: meta_description},
            {property: 'og:url', content: window.location.href},
            {property: 'og:type', content: type},
            {property: 'twitter:creator', content: name},
            {property: 'twitter:title', content: meta_title},
            {property: 'twitter:image', content: meta_image},
            {property: 'twitter:description', content: meta_description},
        ]}  />
    )
}

export default SEO;
