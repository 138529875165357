import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Layout from "../layouts/Layout";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import '../assets/Css/tracking.css'
import LoadingBar from "react-top-loading-bar";

const TrackOrder = () => {
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    ref.current.continuousStart();
    setTimeout(() => {
      ref.current.complete();
    }, 100);
  }, []);
  useEffect(() => {
    document.title = "Ultimate Organic Life | Order Tracking";
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [trackingInfos, setTrackingInfos] = useState([]);
  const [orderNumber, setOrderNumber] = useState();
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const trackOrder = (e) => {
    e.preventDefault();
    setLoading(true);
    ref.current.continuousStart();
    setTrackingInfos([]);

    axios.get("order-track?order_number=" + orderNumber)
      .then(resp => {
        ref.current.complete();
        setLoading(false);
        setIsDataLoaded(true);
        if (resp.data.success) {
            setTrackingInfos(resp.data.data);
        }
        else{
          setTrackingInfos([]);
        }
      }).catch(e => {
            console.log(e);
            setLoading(false);
      });
  };

  // console.log(trackingInfos);
  // const track = (orderNo) => {
  //   // console.log(orderNo + "track theke");
  //     axios.get("order-track?order_number=" + orderNo)
  //     .then(resp => {
  //       console.log(resp.data);
  //       setTrackingInfo(resp.data.data);
  //     });
  // }
  return (
    <div>
      <LoadingBar
      color='#0098b8' 
      ref={ref}
      // progress={progress}
      // onLoaderFinished={() => setProgress(0)}
    />
      <Layout>
        <div className="page-header breadcrumb-wrap">
          <div className="breadcrumb">
            <Link to="/" itemProp="item" title="Home">
              Home
            </Link>
            <span></span> Track Order
            <span></span>
          </div>
        </div>
        <br />
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 pt-1 pb-4 ">
            <form className="" onSubmit={(e)=>trackOrder(e)}>
            <InputGroup className="mb-3">
              <Form.Control
                type="search"
                placeholder="Type Your Order Number"
                aria-label="Type Your Order Number"
                aria-describedby="basic-addon2"
                value={orderNumber}
                onChange={(e) => { setOrderNumber(e.target.value); }}
              />
              <Button variant="outline-secondary p-2" id="button-addon2" type="submit" >
                Track Order
              </Button>
            </InputGroup>
            </form>
          </div>
          <div className="col-md-8 col-lg-8">
            {(trackingInfos?.length<1) && orderNumber && isDataLoaded && (
              <div className="text-warning text-center">
                No Order Found !
              </div>
            )}
              <div className="track">
                <div className="track-content">
                  <div className="track-info">
                    <ul>
                      <li className={trackingInfos?.status?.length>0 && orderNumber ? "active" : "d-none"}>
                        {trackingInfos?.status?.length>0 && (
                            trackingInfos.receiving_timing
                        )
                        }
                        <ul>
                          {trackingInfos && trackingInfos?.status?.length>0 && (
                            trackingInfos.status.map((trackingInfo, index) => {
                              return (
                                <li key={index}>
                                  <h5>{trackingInfo.time}</h5>
                                  <div> <b>{trackingInfo.status}</b>
                                    Comment: <strong> {trackingInfo.comment}</strong> <br/>
                                    Date: <strong>{trackingInfo.date}</strong>
                                  </div>
                                </li>
                              );
                            })
                          )
                          }
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
          </div>
        </div>

      </Layout>
    </div>
  );
};

export default TrackOrder;
