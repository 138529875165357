import React, { useEffect, useState, useRef } from "react";
import Categories from "../components/Categories";
import NewsFlash from "../components/NewsFlash";
import { useCart } from "react-use-cart";
import { NavLink } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import CartPopup from "../components/CartPopup";
import Swal from "sweetalert2";
import Search from "../components/Search";
import { useDispatch, useSelector } from "react-redux";
import { incrementByAmount } from "../store/Counter";
import { setUserData } from "../store/UserData";
import Skeleton from 'react-loading-skeleton';
import LoadingBar from "react-top-loading-bar";
import CoinImg from '../assets/images/Icon/coins.svg'
import { setCustomerAddressData } from "../store/checkoutData/CustomerAddressData";
import FlashNewsImage from "../components/FlashNewsImage";
import { setFlashNewsData } from "../store/layoutData/FlashNewsData";
import Organic_Life_Logo from "../assets/images/logo/Organic_Life_Logo.svg"
import DeliveryTruck from '../assets/images/Icon/delivery-truck-icon.svg'

const Header = () => {
  const ref = useRef(null);
  const {
    totalUniqueItems
  } = useCart();

  const dispatch = useDispatch();
  const { wishlistCount } = useSelector((state) => state.wishlistCount);
  const { HeaderData } = useSelector((state) => state.HeaderData);
  const { UserData } = useSelector((state) => state.UserData);
  const { FlashNewsData } = useSelector((state) => state.FlashNewsData);
  const [flashNewsModalData, setFlashNewsModalData] = useState();
  const [flashNews, setFlashNews] = useState(false);
  // console.log(UserData);
  useEffect(() => {
    if (FlashNewsData && FlashNewsData.image && window.location.pathname === '/') {
      setTimeout(() => {
        setFlashNews(true);
        setFlashNewsModalData(FlashNewsData);
        dispatch(setFlashNewsData(null));
      }, 1500);
    }
  }, [FlashNewsData, flashNews]);

  // console.log(FlashNewsData)
  const [show, setShow] = useState(false);
  const [searchShow, setSearchShow] = useState(true);
  let navigate = useNavigate();
  // console.log(show);
  let loadCheck = 0;
  useEffect(() => {
    if (loadCheck!=1 && localStorage.getItem("user") && UserData?.customer_id && wishlistCount<1) {
      loadCheck=1;
      axios.get("get-wishlist/" + UserData.customer_id)
        .then(resp => {
          // console.log(resp.data.data);
          if (resp.data.success === true) {
            dispatch(incrementByAmount(resp.data.data.length));
          }
          else if (resp.data.code === 401 || resp.data.message === "Authorization token invalid, You cannot proceed") {
            navigate("/login?q=You%20Have%20To%20Login%20Again", { replace: true });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);



  const logout = () => {
    ref.current.continuousStart();
    if (UserData) {
      axios.post("customer/logout")
        .then(function (resp) {
          // console.log(resp.data);
          if (resp.data.success) {
            localStorage.removeItem("user");
            dispatch(setUserData(null));
            dispatch(setCustomerAddressData(null));
            dispatch(incrementByAmount(0));
            ref.current.complete();
            Swal.fire({
              position: 'center',
              icon: 'info',
              title: 'Successfully logged out',
              showConfirmButton: false,
              timer: 1500
            });
            navigate("/", { replace: true });

          }
          else {
            localStorage.removeItem("user");
            dispatch(setUserData(null));
            dispatch(incrementByAmount(0));
            dispatch(setCustomerAddressData(null));
          }
          // console.log(defaultAddress);
        })
        .catch((err) => {
          ref.current.complete();
          console.log(err);
          localStorage.removeItem("user");
          dispatch(setUserData(null));
          dispatch(incrementByAmount(0));
          dispatch(setCustomerAddressData(null));
          navigate("/", { replace: true });
        });

    } else {
      localStorage.removeItem("user");
      dispatch(setUserData(null));
      dispatch(incrementByAmount(0));
      dispatch(setCustomerAddressData(null));
      navigate("/", { replace: true });
    }
  };


  return (
    <>
      <LoadingBar color='#0098b8' ref={ref} />
      <div className="header-area header-style-1 header-height-2 ">
        <div className="header-top header-top-ptb-1 d-none d-lg-block top_underlines">
          {/* <div className="container-fluid"> */}
          <div className="row align-items-center top-news-bar p-0">
            <div className="col-xl-3 col-lg-6 p-0">
              <div className="header-info">
                <ul>
                  <li>
                    <Link to="/about-us">
                      <span>About Us</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/track-order" className="d-flex">
                      <img src={DeliveryTruck} width={13} height={13} />&nbsp;<span>Order Tracking</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-6 d-none d-xl-block">
              <div className="text-center">
                <div id="news-flash" className="d-inline-block">
                  <ul>
                    <NewsFlash />
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-6">
              <div className="header-info header-info-right">
                <ul>
                  <li>
                    {HeaderData && HeaderData.hotline_description_top ? (
                      <>
                        {HeaderData.hotline_description_top} &nbsp;

                        <a href={"tel:" + HeaderData.hotline_no}><img className="svgInject" alt="Wishlist" src="/assets/themes/nest/imgs/theme/icons/phone-call.svg" width={12} height={12} />&nbsp;<strong className="text-brand">{HeaderData.hotline_no}</strong></a>
                      </>
                    ) : (
                      <Skeleton count={1} width={200} />
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* ====================>SERACH HEADER */}
          <div className="row ">
            <div className="header-middle header-middle-ptb-1 d-none d-lg-block ">
              {/*  <!-- <div className="container"> --> */}
              <div className="header-wrap">
                <div className="logo logo-width-1">
                  {HeaderData && HeaderData.site_logo !== undefined ? (
                    <Link to="/" className="pt-2 pb-2">
                      <img src={HeaderData && HeaderData.site_logo !== '' && HeaderData.site_logo} style={{ width: "150px" }} alt="LOGO not found" />
                      {/* <img src={Organic_Life_Logo} style={{ width: "150px" }} alt="LOGO not found" /> */}
                    </Link>
                  ) : (
                    // <Link to="/" className="">
                    //   <img src="/assets/images/logo/Original_logo.png" style={{ width: "150px" }} alt="LOGO not found" />
                    // </Link>
                    <Skeleton circle height={150} className={'p-1'} width={150} />
                  )}
                </div>
                <div className="header-right">
                  <Search show={searchShow} onSearchClickOutside={() => { setSearchShow(false) }} />

                  <div className="header-action-right">
                    <div className="header-action-2">
                      <div className="header-action-icon-2">
                        <Link to={localStorage.getItem("user") && UserData ? "/wish-list" : "/login?q=You%20Have%20To%20Login%20First%20For%20Wishlist"}>
                          <img className="svgInject" alt="Wishlist" src="/assets/themes/nest/imgs/theme/icons/icon-heart.svg" />
                          {/* <img className="svgInject" alt="Wishlist" src="/assets/images/topheader/wishlist.png" width={30} /> */}
                          <span className="pro-count blue wishlist-count"> {wishlistCount} </span>
                        </Link>
                        <Link to={localStorage.getItem("user") && UserData ? "/wish-list" : "/login?q=You%20Have%20To%20Login%20First%20For%20Wishlist"}>
                          <span className="lable">Wishlist</span>
                        </Link>
                      </div>
                      {/* Cart */}
                      <div className="header-action-icon-2">
                        <Link className="mini-cart-icon" to="/cart">
                          {/* <img alt="Cart" src="/assets/themes/nest/imgs/theme/icons/icon-cart.svg" /> */}
                          <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4f4f4f">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                          </svg>

                          <span className="pro-count blue">{totalUniqueItems}</span>
                        </Link>
                        <Link to="/cart">
                          <span className="lable">Cart</span>
                        </Link>
                        {totalUniqueItems === 0 && (
                          <div className="cart-dropdown-wrap cart-dropdown-hm2 cart-dropdown-panel">
                            <span>No products in the cart.</span>
                          </div>
                        )}
                        {totalUniqueItems !== 0 && <CartPopup />}
                      </div>
                      <div className="header-action-icon-2">

                        {!localStorage.getItem("user") && UserData == null && (
                          <div><Link to="#">
                            <img className="svgInject " alt="Account" src="/assets/themes/nest/imgs/theme/icons/icon-user.svg" />
                            {/* <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"></path></svg> */}
                          </Link>
                            <Link to="#">
                              <span className="lable">Account</span>
                            </Link>
                            <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                              <ul>
                                <li>
                                  <Link to="/login">
                                    <i className="fi fi-rs-user mr-10"></i>Login
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/register">
                                    <i className="fi fi-rs-user-add mr-10"></i>Register
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                        {UserData && UserData.customer_name && (
                          <div>
                            <div className="header-action-icon-2">
                              <Link className="svgInject rounded-circle" to="/customer/dashboard">
                                <img className="svgInject rounded-circle" alt="Account" src="/assets/themes/nest/imgs/theme/icons/icon-user.svg" />
                              </Link>
                              <Link to="/customer/dashboard">
                                <span className="lable ml-0">{UserData.customer_name.slice(0, 12) + '...'}</span>
                              </Link>
                              <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                                <ul>
                                  <li>
                                    <Link to="/customer/dashboard">
                                      <i className="fi fi-rs-user mr-10"></i>My Account
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/customer/rewards">
                                      <img src={CoinImg} className="img-fluid" width={14} />&nbsp; Rewards
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/customer/orders">
                                      <i className="fi fi-rs-location-alt mr-10"></i>Orders
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/wish-list">
                                      <i className="fi fi-rs-heart mr-10"></i>My Wishlist
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/customer/profile">
                                      <i className="fi fi-rs-settings-sliders mr-10"></i>Update profile
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" onClick={logout}>
                                      <i className="fi fi-rs-sign-out mr-10"></i>Sign out
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            {/* ================================================== */}

                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
          {/* ====================>SERACH HEADER-2 */}
          <div className="row">
            <div className="header-bottom header-bottom-bg-color  sticky-bar ">
              {/*   <!-- <div className="container"> --> */}
              <div className="header-wrap header-space-between position-relative">
                <div className="logo logo-width-1 d-block d-lg-none">
                  <Link to="/">
                    <img src="" alt="Ultimate Organic Ecommerce" />
                  </Link>
                </div>

                <div className="header-nav d-none d-lg-flex">
                  <div className="main-categories-wrap d-none d-lg-block">
                    <NavLink to="" className="categories-button-active NavLink" onClick={() => { setShow(!show); }}>
                      {/* <span className="fi-rs-apps"></span>  */}
                      {/* <img className="img-fluid mr-5" alt="Wishlist" src="/assets/images/topheader/category-circle.svg" width={16} /> */}
                      <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="#253d4e" stroke-width="1.5" stroke="#253d4e" x="0px" y="0px" viewBox="0 0 943.5 880"
                        style={{ enableBackground: "new 0 0 943.5 880" }}>
                        <path d="M333.4,397.1H120.8c-44.8,0-81.1-36.4-81.2-81.2V106c0.1-44.8,36.4-81.1,81.2-81.2h212.6c44.8,0.1,81.1,36.4,81.2,81.2
	v209.9C414.5,360.7,378.2,397,333.4,397.1z M120.8,87.7c-10.1,0-18.3,8.2-18.3,18.3v209.9c0,10.1,8.2,18.3,18.3,18.3h212.6
	c10.1,0,18.3-8.2,18.3-18.3V106c0-10.1-8.2-18.3-18.3-18.3H120.8z"/>
                        <path d="M718.5,397.1c-102.8-0.8-185.4-84.7-184.7-187.4C534.6,106.9,618.5,24.2,721.3,25c102.2,0.8,184.7,83.8,184.7,186
	C905.4,314.1,821.6,397.3,718.5,397.1z M718.5,87.8c-68,0.8-122.6,56.5-121.8,124.6c0.8,68,56.5,122.6,124.6,121.8
	c67.5-0.8,121.8-55.7,121.8-123.2c-0.4-68.4-56.2-123.6-124.6-123.3V87.8z"/>
                        <ellipse cx="719.9" cy="210.9" rx="134.8" ry="124.9" />
                        <path d="M333.4,851.1H120.8c-44.8,0-81.1-36.4-81.2-81.2V560c0.1-44.8,36.4-81.1,81.2-81.2h212.6c44.8,0.1,81.1,36.4,81.2,81.2V770
	C414.5,814.8,378.2,851.1,333.4,851.1z M120.8,541.7c-10.1,0-18.3,8.2-18.3,18.3V770c0,10.1,8.2,18.3,18.3,18.3h212.6
	c10.1,0,18.3-8.2,18.3-18.3V560c0-10.1-8.2-18.3-18.3-18.3H120.8z"/>
                        <path d="M826.2,851.1H613.6c-44.8,0-81.1-36.4-81.2-81.2V560c0.1-44.8,36.4-81.1,81.2-81.2h212.6c44.8,0.1,81.1,36.4,81.2,81.2V770
	C907.3,814.8,871,851.1,826.2,851.1z M613.6,541.7c-10.1,0-18.3,8.2-18.3,18.3V770c0,10.1,8.2,18.3,18.3,18.3h212.6
	c10.1,0,18.3-8.2,18.3-18.3V560c0-10.1-8.2-18.3-18.3-18.3H613.6z"/>
                      </svg>

                      <span className="ml-5 et">Browse</span> All Categories
                      {show ? <i className="fi-rs-angle-up"></i> : <i className="fi-rs-angle-down"></i>}
                    </NavLink>
                  </div>

                </div>
                {show === true && (
                  <Categories show={show} onClickOutside={() => { setShow(!show) }} />
                )}
                <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
                  <nav>
                    <ul>
                      <li>
                        <Link to="/" className={window.location.pathname === '/' ? 'd-none' : 'menu'} target="_self">
                          Home
                        </Link>
                      </li>

                      <li>
                        <Link to="/products" target="_self" className={window.location.pathname === '/products' ? 'active' : ''}>
                          All Products
                        </Link>
                      </li>
                      <li>
                        <Link to="/faq" target="_self" className={window.location.pathname === '/faq' ? 'active' : ''}>
                          FAQ
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact" target="_self" className={window.location.pathname === '/contact' ? 'active' : ''}>
                          Contact
                        </Link>
                      </li>
                      <li>
                        <Link to="/blogs" target="_self" className={window.location.pathname === '/blogs' ? 'active' : ''}>
                          Blogs
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>

                <div className="hotline d-none d-lg-flex align-items-center">

                  {HeaderData && (
                    <>
                      {/* <img src={HeaderData.hotline_logo ?
               axios.defaults.baseURL.slice(0, -4) + "frontend/images/social_icons/" + HeaderData.hotline_logo :
                "/assets/images/topheader/icon-headphone.svg"} alt="hotline" /> */}
                      <img src="/assets/images/topheader/call-center.png" alt="hotline" width={32} height={32} />
                      <p>
                        {HeaderData.hotline_no ? (
                          <>
                            <a href={"tel:" + HeaderData.hotline_no}>{HeaderData.hotline_no}</a>
                            <span>{HeaderData.hotline_description_bottom}</span>
                          </>
                        ) : (
                          <>
                            <Skeleton className={'mt-3'} width={160} height={20} />
                            <Skeleton className={"mb-0 mt-0 pt-0"} width={160} />
                          </>
                        )}
                      </p>
                    </>
                  )}

                </div>
                <div className="header-action-icon-2 d-block d-lg-none">
                  <div className="burger-icon burger-icon-white">
                    <span className="burger-icon-top"></span>
                    <span className="burger-icon-mid"></span>
                    <span className="burger-icon-bottom"></span>
                  </div>
                </div>
                <div className="header-action-right d-block d-lg-none">
                  <div className="header-action-2">
                    <div className="header-action-icon-2">
                      <Link to="#/compare">
                        <img alt="Compare" src="/assets/images/topheader/icon-compare.svg" />
                        <span className="pro-count white compare-count">0</span>
                      </Link>
                    </div>
                    <div className="header-action-icon-2">
                      <Link to="#/wishlist">
                        <img alt="Wishlist" src="/assets/images/topheader/icon-heart.svg" />
                        <span className="pro-count white wishlist-count"> 0 </span>
                      </Link>
                    </div>
                    <div className="header-action-icon-2">
                      <Link className="mini-cart-icon" to="#">
                        <img alt="Cart" src="/assets/images/topheader/icon-cart.svg" />
                        <span className="pro-count white">0</span>
                      </Link>
                      <div className="cart-dropdown-wrap cart-dropdown-hm2 cart-dropdown-panel">
                        <span>No products in the cart.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      <FlashNewsImage show={flashNews}
        onHide={() => setFlashNews(false)} flashNewsData={flashNewsModalData} />
    </>
  );
};

export default React.memo(Header); 
