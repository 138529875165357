import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setUserData } from "../store/UserData";
import axios from "axios";
import Swal from "sweetalert2";
import Layout from "../layouts/Layout";
import LoadingBar from "react-top-loading-bar";
import { setCustomerAddressData } from "../store/checkoutData/CustomerAddressData";
import { useCart } from "react-use-cart";
import { incrementByAmount } from "../store/Counter";

import viewPass from '../assets/images/view.png';
import hidePass from '../assets/images/hide.png';

const Login = () => {
  const ref = useRef(null);
  const search = useLocation().search;
  const queryParam = new URLSearchParams(search);
  const dispatch = useDispatch();
  let [username, setUserName] = useState("");
  let [password, setPassword] = useState("");
  let [showPassword, setShowPassword] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  let { addItem, emptyCart, inCart } = useCart();

  useEffect(() => {
    ref.current.continuousStart();
    document.title = "Ultimate Organic Life | Login";
    window.scrollTo(0, 0);
    setTimeout(() => {
      ref.current.complete();
    }, 200);
  }, []);
  useEffect(() => {
    if (queryParam.get('q') != null) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: queryParam.get('q'),
        // showConfirmButton: false,
        // timer: 2000
      })
    }
    localStorage.removeItem("user");
    dispatch(setUserData(null));
    dispatch(incrementByAmount(0));
    dispatch(setCustomerAddressData(null));
  }, []);
  const loginSubmit = (e) => {
    var obj = { user_input: username, password: password };
    ref.current.continuousStart();
    axios
      .post("customer/login", obj)
      .then(function (resp) {
        // console.log(resp.data);
        ref.current.complete();
        var data = resp.data;

        if (data.success === false) {
          ref.current.complete();
          setError(resp.data.message);
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: resp.data.message,
            showConfirmButton: false,
            timer: 2000
          })
        }
        // console.log(localStorage.getItem("user"));
        if (data.status) {
          var user = { token: data.token, customer_id: data.data.id, customer_name: data.data.customer_name, user: data.data };
          dispatch(setUserData(user));
          dispatch(setCustomerAddressData(null));
          localStorage.setItem("user", JSON.stringify(user));
          axios.defaults.headers.common["Authorization"] = 'Bearer ' + data.token;
          axios.get("get-cart/" + data.data.id)
            .then(resp => {
              if (resp.data.success) {
                // console.log(resp.data.data.data);
                resp.data.data.data.forEach(cart => {
                  if (!inCart(cart.id)) {
                    addItem({
                      variation: {
                        id: cart.attribute_id, value: cart.attribute_value, stock: cart.product_stock, attribute_final_price: cart.product_price, old_price: '', discount: ''
                      },
                      slug: cart.product_slug,
                      name: cart.product_name,
                      image: cart.product_image,
                      price: cart.product_price,
                      product_id: cart.id,
                      id: cart.id,
                      is_enable_point: cart.is_enable_point,
                    }, cart.quantity_of_orders);

                  }
                });
              }
            }).catch(err => {
              console.log(err);
            });

          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Successfully logged in',
            showConfirmButton: false,
            timer: 1500
          });
          navigate("/", { replace: true });
          // window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    e.preventDefault();
  };

  return (
    <div>
      <LoadingBar
        color='#0098b8'
        ref={ref}
      // progress={progress}
      // onLoaderFinished={() => setProgress(0)}
      />
      <Layout>
        <main className="main" id="main-section">
          <div className="page-header breadcrumb-wrap">
            <div className="breadcrumb" itemScope itemType="http://schema.org/BreadcrumbList">
              <a href="/#" itemProp="item" title="Home">
                Home
                <meta itemProp="name" content="Home" />
                <meta itemProp="position" content="1" />
              </a>
              <span></span> Login
              <meta itemProp="name" content="Login" />
              <meta itemProp="position" content="2" />
            </div>
          </div>
          <div className="page-content pt-150 pb-150">
            <div className="row">
              <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                <div className="row">
                  <div className="col-lg-6 pr-30 d-none d-lg-block">
                    <img className="border-radius-15" src={"/assets/images/logo/Original_logo.png"} alt="" />
                  </div>
                  <div className="col-lg-6 col-md-8">
                    <div className="login_wrap widget-taber-content background-white">
                      <div className="padding_eight_all bg-white">
                        <div className="heading_s1">
                          <h1 className="mb-5">Login</h1>
                          <p className="mb-30">
                            Don&#039;t have an account? <Link to="/register">Create one</Link>
                          </p>
                        </div>
                        <span style={{ color: "red" }}>{error}</span>
                        <form
                          onSubmit={(e) => {
                            loginSubmit(e);
                          }}
                        >
                          <div className="form-group">
                            {/*   <input type="text" required name="text" placeholder="Username or Email*" /> */}
                            <input
                              type="text"
                              name="username"
                              id="login_username"
                              placeholder="Phone Or Email*"
                              required
                              value={username}
                              onChange={(e) => setUserName(e.target.value)}
                            />
                          </div>
                          {/*
                             */}{" "}
                          <div className="form-group position-relative">
                            <input
                              type={showPassword ? 'text' : 'password'}
                              name="password"
                              id="login_password"
                              placeholder="Password*"
                              required
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              autoComplete={'false'}
                            />
                            <div className='position-absolute eywshowhide'>

                              <button className="border-0 passwordshoingbtn"
                                type='button' onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ?
                                  <img src={viewPass} alt="" />
                                  :
                                  <img src={hidePass} alt="" />
                                }
                              </button>
                            </div>
                          </div>
                          <div className="login_footer form-group mb-50">
                            <Link className="text-muted" to="/forgot-password">
                              Forgot password?
                            </Link>
                          </div>
                          <div className="form-group">
                            <button type="submit" className="btn btn-heading btn-block hover-up">
                              Login
                            </button>
                          </div>
                          <div className="text-left"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </div>
  );
};

export default Login;
