import React from "react";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, Router, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import searchIcon from '../assets/images/Icon/Search-white.svg';

const Search = (props) => {
  const [productName, setProductName] = useState(null);
  const [cateID, setCateID] = useState("");
  const [cateName, setCateName] = useState("");
  const [query, setQuery] = useState('');
  let navigate = useNavigate();
  const { CategoryData } = useSelector((state) => state.CategoryData);

  useEffect(() => {
    if (cateID && productName) {
      setQuery("?platform=web&category_id=" + cateID + "&product_name=" + productName + "&paginate=" + 15);
    }
    else if (productName) {
      setQuery("?platform=web&product_name=" + productName + "&paginate=" + 15);
    }
  }, [cateID, productName]);

  const [products, setProducts] = useState([]);
  useEffect(() => {
    if (productName !== null) {
      axios.get("products/search" + query)
        .then(resp => {
          // console.log(resp);
          setProducts(resp.data.data.data);
        });
    }
  }, [query]);

  const ref = useRef(null);
  const { onSearchClickOutside } = props;
  const [show, setShow] = useState(props.show);
  useEffect(() => {
    const handleSearchClickOutside = (event) => {

      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
        onSearchClickOutside && onSearchClickOutside();
      }
    };
    document.addEventListener('click', handleSearchClickOutside, true);
    return () => {
      document.removeEventListener('click', handleSearchClickOutside, true);
    };
  }, [onSearchClickOutside]);
  // console.log(products)


  const searchPageRedirect = (e) => {
    e.preventDefault();
    navigate("/search?q=" + productName,{ replace: true });
  }
  return (
    <div ref={ref} className="search-style-2">
      <form className="form--quick-search" onSubmit={(e) => { searchPageRedirect(e) }}>
        <div className="form-group--icon position-relative">
          <div className="product-cat-label">{cateName ? (cateName) : ('All Categories')}</div>
          <select className="product-category-select" name="category" onChange={(e) => { setCateID(e.target.value); setCateName(e.target.childNodes[e.target.selectedIndex].getAttribute('name')) }}
            value={cateID}>
            <option value="">All Categories</option>
            {(
              CategoryData.map((category, index) => {
                return (
                  <option key={index} value={category.id} name={category.name}> {category.name} </option>
                );
              })
            )}
          </select>
        </div>
        <input
          type="text"
          className="input-search-product pr-60"
          name="productName"
          placeholder="Search for items…"
          autoComplete="off"
          onChange={(e) => { setProductName(e.target.value); setShow(true) }}
          onClick={(e) => { setProductName(e.target.value); setShow(true) }}
          value={productName}
        />

        <div className="search_icon_btn" onClick={(e) => { searchPageRedirect(e) }}>
          <img src={searchIcon} className="img-fluid" />
        </div>

        <div className={productName && show ? 'panel--search-result active' : 'panel--search-result'}>
          <div className="panel__content">
            <div className="row py-2 mx-0 ">
              {products && (
                products.map((product, index) => {
                  if (product != null) {
                    return (
                      <div key={index} className="col-12 px-1 px-md-2 py-1 product-cart-wrap search_product_wrap border-0 rounded-0">
                        <div className="row mx-md-2 gx-md-2 gx-1">
                          <div className="col-xl-2 col-3 product-img-action-wrap mb-0">
                            <div className="product-img product-img-zoom">
                              <Link to={"/products/" + product.slug}>
                                <img className="default-img" src={product.image && (product.image.small)} alt={'product'} />
                              </Link>
                            </div>
                          </div>
                          <div className="col-xl-10 col-9 product__content">
                            <div className="product-content-wrap m-4">
                              <Link className="product__title" to={"/products/" + product.slug}>{product.name}</Link>
                              <br /><br />
                              <div className="rating_wrap">
                                {/* <div class="product-rate d-inline-block">
                                    <div class="product-rating" style={{width:'50%'}}></div>
                                  </div>
                                  <span class="rating_num">(4)</span> */}
                              </div>
                              {/* <div className="product-price">
                              <span>৳{Math.ceil(product && Number(product.final_product_price).toFixed(2))}</span>
                              <span className="old-price">{product && product.formatted_regular_price}</span>
                            </div> */}
                              {isNaN(product.discount) ? (
                                <div className="product-price">
                                  <span>{product.formatted_final_product_price}</span>
                                  <span className="old-price">{product.formatted_regular_price}</span>
                                </div>
                              ) : (
                                <div className="product-price text-start">
                                  <span>{product.formatted_final_product_price}</span>
                                  {/* <span className="old-price">{product.formatted_regular_price}</span> */}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })
              )}

              {products.length <= 0 && (
                <span className="text-center text-warning">Ops! No Products Found</span>
              )}

            </div>

          </div>
          <div className="panel__footer text-center">
            {/* <Link to="#">See all results</Link> */}
          </div>
        </div>
        {/* <div className="panel--search-result"></div> */}
      </form>
    </div>
  );
};

export default Search;
