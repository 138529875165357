import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Font } from "@react-pdf/renderer";
import Nikosh from '../../assets/fonts/Nikosh.ttf';
// Font.register({ family: 'Nikosh', src: '../assets/fonts/Nikosh.ttf', fontStyle: 'normal', fontWeight: 'normal' });
Font.register({
  family: 'Nikosh',
  fonts: [
    {
      src: Nikosh,
    },
  ],
});
// Create styles
const styles = StyleSheet.create({
    page: {
      backgroundColor: "white",
      color: "black",
    },
    section: {
      margin: 10,
      padding: 10
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
    text: {
      fontFamily: 'Nikosh',
    },
  });

// Create Document Component
const MyDocument = () => (
    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>Hello</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.text}>আমি বাংলা পরীক্ষা করছি</Text>
      </View>
    </Page>
  </Document>
);
export default MyDocument;