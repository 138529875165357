import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useNavigate,useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import LoadingBar from 'react-top-loading-bar';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { setDhakaShippingZoneData } from '../store/checkoutData/DhakaShippingZoneData';
import { setOutsideShippingZoneData } from '../store/checkoutData/OutsideShippingZoneData';
import SelectSearch from 'react-select-search';

const EditAddress = (props) => {

  const [loading, setLoading] = useState(false);
  const { add_id } = useParams();
  const ref = useRef(null);
  let navigate = useNavigate();
  const [errorList,setError]= useState(); 
  let [username, setUsername] = useState("");
  let [email, setEmail] = useState("");
  let [contact, setContact] = useState("");
  let [city, setCity] = useState("inside_dhaka");
  let [area, setArea] = useState("");
  let [areaID, setAreaId] = useState("");
  let [zip, setZip] = useState("");
  let [defaultValue, setDefault] = useState(false);
  let [address, setAddress] = useState("");
  const [shippingZones, setShippingZones] = useState([]);
  const { DhakaShippingZoneData } = useSelector((state) => state.DhakaShippingZoneData);
  const { OutsideShippingZoneData } = useSelector((state) => state.OutsideShippingZoneData);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    // setTimeout(() => {
    //   ref.current.complete();
    // }, 1000);
  }, []);

  
  useEffect(() => {
    ref.current.continuousStart();
    axios
      .get("edit-customer-address/" + add_id)
      .then(function (resp) {
        ref.current.complete();
        // console.log(resp.data.data[0]);
        setUsername(resp.data.data[0].name);
        setEmail(resp.data.data[0].email);
        setContact(resp.data.data[0].phone);
        if(resp.data.data[0].shipping_id==14){
          setCity('inside_dhaka');
        }
        else{
          setCity('outside_dhaka');
        }
        setArea(resp.data.data[0].area);
        // console.log(area);
        // setAreaId(resp.data.data[0].area_id);
        setZip(resp.data.data[0].zip);
        // let [defaultValue, setDefault] = useState(false);
        setAddress(resp.data.data[0].address);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.customer_id]);
// console.log(area);

  useEffect(() => {
    // console.log(city);
    if (city == 'inside_dhaka') {
      axios.get("ec/area-by-district/dhaka")
        .then(resp => {
          dispatch(setDhakaShippingZoneData(resp.data.data.data));
        }).catch(err => {
          console.log(err);
        });
    }
    else if (city == 'outside_dhaka') {
      axios.get("ec/get-cities")
        .then(resp => {
          dispatch(setOutsideShippingZoneData(resp.data.data));
        }).catch(err => {
          console.log(err);
        });
    }
  }, [city]);

  const handleSubmit = (event) => {
    ref.current.continuousStart();
    var obj = {
        name: username,
        customer_id: props.customer_id,
        email: email,
        phone: contact,
        address: address,
        city: city,
        zip: zip,
        area: area,
        area_id:areaID,
        shipping_id:0,
        is_default:defaultValue
    };
    if(city=='inside_dhaka'){
      obj.shipping_id=14;
      }
      else if(city=='outside_dhaka'){
        obj.shipping_id=15;
      }
// console.log(props.customer_id);
// console.log(obj);
    axios
      .put("update-customer-address/"+add_id, obj)
      .then(function (resp) {
        // console.log(resp.data);
        var data = resp.data;
        // console.log(data);
        if (data.success==false) {
            
          setError(data.message);
        }
        else if(data.message){
          ref.current.complete()
          Swal.fire({
            customClass: {
                icon: 'mt-4'
            },
            position: 'center',
            icon: 'success',
            title: data.message,
            showConfirmButton: true,
        });
        navigate("/customer/address",{ replace: true });
        }
      })
      .catch((err) => {
        console.log(err);
        setError(err.response.data.errors)
      });
    event.preventDefault();
  };
  return (
    <div>
      <LoadingBar
        color='#0098b8' 
        ref={ref}
        // progress={progress}
        // onLoaderFinished={() => setProgress(0)}
      />
      <div className="tab-content account dashboard-content pl-md-50">
        <div className="tab-pane fade active show" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
          <div className="card">
            <div className="card">
              <div className="card-header">
                <h5>Edit Address</h5>
              </div>

              <div className="card-body">
                <form onSubmit={(e) => { handleSubmit(e); }}>
                  <div className="form-group">
                    <label htmlFor="name" className="required">
                      Full Name:
                    </label>{" "}
                    <input
                      id="name"
                      type="text"
                      name="name"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Enter your full name"
                      className="form-control square"
                    />
                    {errorList && (<span className='text-danger'>{errorList.name}</span>)}
                    {/* <span className='text-danger'>{errorList.name}</span> */}
                  </div>{" "}
                  <div className="form-group">
                    <label htmlFor="email">Email:</label>{" "}
                    <input id="email" type="email" name="email" value={email}
                                      onChange={(e) => setEmail(e.target.value)} placeholder="your-email@domain.com" className="form-control square" />
                  {errorList && (<span className='text-danger'>{errorList.email}</span>)}
                  </div>{" "}
                  <div className="form-group">
                    <label htmlFor="phone" className="required">
                      Phone:
                    </label>{" "}
                    <input id="phone" type="text" name="phone" value={contact} onChange={(e) => setContact(e.target.value)} className="form-control square" />
                    {errorList && (<span className='text-danger'>{errorList.phone}</span>)}
                  </div>{" "}
                  <div className="form-group ">
                    <label htmlFor="city" className="required">
                      City:
                    </label>{" "}
                    <select style={{ height: '64px' }} className="form-control address-control-item address-control-item-required"
                      id="city"
                      name="city"
                      value={city}
                      required
                      onChange={(e) => { setCity(e.target.value); setAreaId(null); }}
                    >
                      <option>Your City</option>
                      <option value={'inside_dhaka'}>Inside Dhaka</option>
                      <option value={'outside_dhaka'}>Outside Dhaka</option>
                    </select>
                    {errorList && (<span className='text-danger'>{errorList.city}</span>)}
                  </div>{" "}
                  <div className="form-group ">
                    <label htmlFor="area" className="required">
                      Area:
                    </label>{" "}
                    {city === 'inside_dhaka' && (
                      // <div className="form-group">
                      // <SelectSearch options={DhakaShippingZoneData} value={area} search={true} name="area" placeholder="Select Area" onChange={(selectedValue,selectedOption) => {setArea(selectedOption.name);console.log(selectedOption);}} />
                      // </div>
                      DhakaShippingZoneData ? (
                        <div className="form-group ">
                          <select style={{ height: '64px' }} className="form-control address-control-item address-control-item-required"
                            name="area"
                            required={area === "" ? true : false}
                            value={area}
                            onChange={(e) => setArea(e.target.value)}
                          >
                            <option value={null}>Select Area</option>
                            {DhakaShippingZoneData &&
                              (DhakaShippingZoneData.map((srvzn, index) => {
                                return <option key={index} value={srvzn.name}>{srvzn.name}</option>;
                              }))}
                          </select>
                          {errorList && (
                            <span className="text-danger">
                              {errorList['shipping_details.area'] && errorList['shipping_details.area'][0]}
                            </span>
                          )}
                        </div>
                      ) : (
                        <><Skeleton width={'100%'} height={65} /><br /></>
                      )
                    )}
                    {city === 'outside_dhaka' && (
                      OutsideShippingZoneData ? (
                        <div className="form-group">
                          <SelectSearch options={OutsideShippingZoneData} value={area} search={true} name="area" placeholder="Select Area" onChange={(selectedValue) => { setArea(selectedValue); }} />
                        </div>
                      ) : (
                        <><Skeleton width={'100%'} height={65} /><br /></>
                      )
                    )}
                  </div>{" "}
                  <div className="form-group ">
                    <label htmlFor="zip" className="">
                      Zip:
                    </label>{" "}
                    <input id="zip" type="text" name="zip" value={zip} onChange={(e) => setZip(e.target.value)} placeholder="Enter your city" className="form-control square" />
                  </div>{" "}
                  <div className="form-group">
                    <label htmlFor="address" className="required">
                      Address:
                    </label>{" "}
                    <input
                      id="address"
                      type="text"
                      name="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      required="required"
                      placeholder="Enter your address"
                      className="form-control square"
                    />
                    {errorList && (<span className='text-danger'>{errorList.address}</span>)}
                  </div>{" "}
                  <div className="form-group">
                    <div className="custome-checkbox">
                      <input type="checkbox" name="is_default" value={1} id="is_default" onClick={(e) => setDefault(!defaultValue)} className="form-check-input" />{" "}
                      <label htmlFor="is_default" className="form-check-label">
                        <span>Use this address as default.</span>
                      </label>
                    </div>
                  </div>{" "}
                  <div className="col-md-12">
                    <button type="submit" className="btn btn-fill-out submit">
                      Update address
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditAddress;