import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import Layout from "../../layouts/Layout";
import Swal from "sweetalert2";

const MakePayment = () => {
    const [loading, setLoading] = useState(false);
    const { orderID } = useParams();

    // useEffect(() => {
    //     setLoading(true);
        // setTimeout(() => {
        //     setLoading(false);
        // }, 1000);
    // }, []);
    useEffect(() => {
        document.title = "Ultimate Organic Life - Make Payment";
    }, []);

    let navigate = useNavigate();
    // const [tempCart, setTempCart] = useState();
    const [order, setOrder] = useState();
    const [cart, setCart] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0)
        setLoading(true);
        if(!cart.length>0){
            axios.get("pos-order-payment/" + orderID)
            .then(resp => {
                setLoading(false);
                // console.log(resp.data);
                if (resp.data.success) {
                    setOrder(resp.data.data);
                    setCart(resp.data.data.order_details);
                }
                else {
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'No Data found',
                        // showConfirmButton: false,
                        // timer: 2000
                    });
                    // navigate("/");
                    // window.location.reload(false);
                }
            }).catch(err => {
                console.log(err);
                setLoading(false);
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'No Data found',
                    // showConfirmButton: false,
                    // timer: 2000
                });
                // navigate("/");
                // window.location.reload(false);
              });;
        }
    }, [orderID]);

    const makePayment = () => {
    setLoading(true);
        axios.get('make-payment/'+orderID)
          .then(resp => {
            // console.log('inside then');
            // console.log(resp.data);
            if (resp.data.success) {
              window.location.replace(resp.data.data);
            }
            else {
              setLoading(false);
            //   console.log(resp.data.message);
            }
            // window.location.replace(resp.gatewayPageUrl);
          })
          .catch(err => {
            setLoading(false);
            console.log(err.response);
          });
        }

    return (
        <div>
            {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
            <Layout>
                <div className="page-header breadcrumb-wrap">
                    <div className="breadcrumb">
                        <Link to="/" itemProp="item" title="Home">
                            Home
                        </Link>
                        <span></span> About Us
                        <span></span>
                    </div>
                </div>
                <br />
                <div className="row justify-content-center">
                    <div className="col-12 pt-1 pb-4">
                        <div className="card" style={{paddingLeft: "1rem", paddingRight: "1rem"}}>
                            <div className="card-body" style={{padding: "1rem .25rem 0rem .25rem"}}>
                                <h6 className="m-1">Order Information: <span className="span-class">{order && order.order_number}</span></h6>
                                <h6 className="m-1 d-none d-md-block" style={{float:'left'}}>Order Status: <span className="span-class pl-1" style={{color:'#ba931a'}}>{order && order.status}</span></h6>
                                <h6 className="d-none d-md-block" style={{float:'right'}}>Payment Status: <span className="span-class pl-1" style={{color:'#ba1aae'}}>{order && order.payment_status}</span></h6>
                                <h6 className="m-1 d-block d-md-none" >Order Status: <span className="span-class pl-1" style={{color:'#ba931a'}}>{order && order.status}</span></h6>
                                <h6 className="m-1 d-block d-md-none">Payment Status: <span className="span-class" style={{color:'#ba1aae'}}>{order && order.payment_status}</span></h6>
                            </div>
                            <hr />
                            <button className="btn btn-fill-out mb-3 d-block d-md-none"
                                    onClick={()=>makePayment()}>Make Payment</button>
                            <div className="row">
                                <div className="col-md-12 p-0">

                                    <table className="table table-striped">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th scope="col">Sl</th>
                                                <th scope="col">Description</th>
                                                <th className="text-right" scope="col">Quantity</th>
                                                <th className="text-right" scope="col">Unit Cost</th>
                                                <th className="text-right" scope="col">Line Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {cart && cart.map((item, index) => {
                                            return (
                                                <tr key={index} className="table-data">
                                                    <td scope="row"  data-title="Sl">{index+1}</td>
                                                    <td  data-title="Description">{item.product_name}</td>
                                                    <td className="text-right" data-title="Quantity" style={{paddingRight: "1.5rem"}}>
                                                        {item.product_quantity}</td>
                                                    <td className="text-right pr-3" data-title="Unit Cost">৳{item.product_price}</td>
                                                    <td className="text-right" data-title="Line Total">
                                                    ৳{item.product_price * item.product_quantity}</td>
                                                </tr>
                                             );
                                            })}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div className="row pt-5">
                                <div className="col-md-7">
                                    <div className="row pt-2">
                                        <div className="col-md-5 pr-0 pt-1 font-sml">
                                            <h6>Payment Method: </h6>
                                        </div>
                                        <div className="col-md-7 p-0 ps-3 font-sml">
                                            <p>{order && order.payment_method}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 pr-0 pt-1 font-sml">
                                            <h6>Order Note: </h6>
                                        </div>
                                        <div className="col-md-7 p-0 ps-3 font-sml">
                                        {order && order.order_note}
                                        </div>
                                    </div>
                                    <button className="btn btn-fill-out submit d-none d-md-block" style={{position:'absolute', bottom: '5%', left: '22%'}}
                                    onClick={()=>makePayment()}>Make Payment</button>
                                </div>
                                <div className="col-md-5">
                                    <table className="table">

                                        <tbody>
                                            <tr className="table-data">
                                                <td>Sub Total: </td>
                                                <td style={{textAlign:'right'}}>
                                                ৳{order && order.subtotal}
                                                </td>
                                            </tr>
                                            <tr className="table-data">
                                                <td>Coupon:</td>
                                                <td style={{textAlign:'right'}}>
                                                ৳{order && order.coupon_amount ? order.coupon_amount : '0.00'}
                                                </td>
                                            </tr>

                                            <tr className="table-data">
                                                <td>Discount:</td>
                                                <td style={{textAlign:'right'}}>
                                                ৳{order && order.discount ? order.discount : '0.00'}
                                                </td>
                                            </tr>
                                            <tr className="table-data">
                                                <td>Shipping: </td>
                                                <td style={{textAlign:'right'}}>
                                                ৳{order && order.shipping_charges}
                                                </td>
                                            </tr>
                                            <tr className="table-data" style={{background: "#dee2e6"}}>
                                                <td>Total: </td>
                                                <td style={{textAlign:'right'}}>
                                                ৳{order && order.pay_amount}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                
                                <button className="btn btn-fill-out mb-3 d-block d-md-none mr-4"
                                    onClick={()=>makePayment()}>Make Payment</button>
                            </div>
                        </div>
                    </div>
                </div>

            </Layout>
        </div>
    );
};

export default MakePayment;
