import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Layout from "../layouts/Layout";
import Parser from 'html-react-parser';
import Skeleton from "react-loading-skeleton";

const ReturnExchange = () => {
  const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     setLoading(true);
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 500);
    // }, []);

  useEffect(() => {
    document.title = "Ultimate Organic Life - Return & Exchange";
    window.scrollTo(0, 0);
  }, []);
  let loadCheck = 0;
  const [returnExchange, setReturnExchange] = useState();
  useEffect(() => {
    if(loadCheck===0){
      loadCheck=1;
      axios.get("returns-exchanges")
      .then(resp => {
        // console.log(resp.data.description);
        setReturnExchange(resp.data.description);
      });
    }
  }, []);

  return (
    <div>
      {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
      <Layout>
      <div className="page-header breadcrumb-wrap">
          <div className="breadcrumb">
            <Link to="/" itemProp="item" title="Home">
              Home
            </Link>
            <span></span> Returns & Refund
            <span></span>
          </div>
        </div>
        <br/>
        <div className="row justify-content-center">
          <div className="col-12 pt-1 pb-4">
          {returnExchange ? (
            Parser(returnExchange)
            ) : (
              <>
              <h5><Skeleton width={'25%'} /></h5>
              <Skeleton count={6} />
              <h5><Skeleton width={'25%'} /></h5>
              <Skeleton count={6} />
              </>
            )}
          </div>
        </div>

      </Layout>
    </div>
  );
};

export default ReturnExchange;
