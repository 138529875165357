import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Layout from "../layouts/Layout";
import Parser from 'html-react-parser';
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";

const AboutUs = () => {
  const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     setLoading(true);
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 1000);
    // }, []);
  useEffect(() => {
    document.title = "Ultimate Organic Life | About Us";
  }, []);
  let loadCheck = 0;
const [aboutUs, setAboutUs] = useState();
  useEffect(() => {
    if(!aboutUs && loadCheck===0){
      loadCheck=1;
    axios.get("about-us")
      .then(resp => {
        // console.log(resp.data);
        // setLoading(false);
        if(resp.data.success){
        setAboutUs(resp.data.data.description);
        }
        else{
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'No Data found',
            // showConfirmButton: false,
            // timer: 2000
          })
        }
      });
    }
  }, [aboutUs]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
      <Layout>
      <div className="page-header breadcrumb-wrap">
          <div className="breadcrumb">
            <Link to="/" itemProp="item" title="Home">
              Home
            </Link>
            <span></span> About Us
            <span></span>
          </div>
        </div>
        <br/>
        <div className="row justify-content-center">
          <div className="col-12 pt-1 pb-4">
          {aboutUs ? (
            Parser(aboutUs)
            ) : (
              <>
              {/* <h3><Skeleton width={'25%'} /></h3> */}
              <Skeleton count={4} />
              </>
            )
            }
          </div>
        </div>

      </Layout>
    </div>
  );
};

export default AboutUs;
