import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2'
import LoadingBar from 'react-top-loading-bar';
import viewPass from '../assets/images/view.png';
import hidePass from '../assets/images/hide.png';

const ChangePassword = (props) => {

  useEffect(() => {
    document.title = "Ultimate Organic Ecommerce | Change Password";
  }, []);
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);

  let navigate = useNavigate();
  const [errorList, setError] = useState([]);
  let [currentPassword, setCurrentPassword] = useState("");
  let [password, setPassword] = useState("");
  let [passwordConfirm, setPasswordConfirm] = useState("");
  let [showPassword, setShowPassword] = useState(false);
  let [showNewPassword, setShowNewPassword] = useState(false);
  let [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassErr, setConfirmPassErr] = useState("");
  const confirmPass = () => {
    if (password === passwordConfirm) {
      setConfirmPassErr('');
    }
    else {

      setConfirmPassErr('Password and confirm password does not match!');
    }
  }

  const handleSubmit = (event) => {
    ref.current.continuousStart();
    var obj = {
      id: props.customer_id,
      current_password: currentPassword,
      password: password,
      password_confirmation: passwordConfirm
    };
    axios
      .post("customer/change-password", obj)
      .then(function (resp) {
        ref.current.complete();
        // console.log(resp.data);
        var data = resp.data;
        if (data.success_message) {
          Swal.fire({
            customClass: {
              icon: 'mt-4'
            },
            position: 'center',
            icon: 'success',
            title: data.success_message,
            showConfirmButton: false,
            timer: 800
          });

          navigate("/customer/dashboard", { replace: true });
        }
        else if (data.failed_message) {
          Swal.fire({
            customClass: {
              icon: 'mt-4'
            },
            position: 'center',
            icon: 'error',
            title: data.failed_message,
            showConfirmButton: true,
            // timer: 8600
          }).then((result) => {
            if (result.isConfirmed) {
              // location.reload();
            }
          });

        }
      })
      .catch((err) => {
        ref.current.complete();
        setError(err.response.data.errors)
      });
    event.preventDefault();
  }

  return (
    <div>
      <LoadingBar
        color='#0098b8'
        ref={ref}
      // progress={progress}
      // onLoaderFinished={() => setProgress(0)}
      />
      <div className="tab-content account dashboard-content pl-md-50 pt-md-55">
        <div className="tab-pane fade active show" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
          <div className="card">
            <div className="card">
              <div className="card-header">
                <h5>Change Password</h5>
              </div>
              <div className="card-body">
                <form onSubmit={(e) => {
                  handleSubmit(e);
                }}>
                  <div className="form-group position-relative changepass">
                    <label className="required" htmlFor="current_password">
                      Current password:
                    </label>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="form-control square"
                      name="current_password"
                      id="current_password"
                      placeholder="Current password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                    <div className='position-absolute eywshowhide'>

                      <button className="border-0 passwordshoingbtn"
                        type='button' onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ?
                          <img src={viewPass} alt="" />
                          :
                          <img src={hidePass} alt="" />
                        }
                      </button>
                    </div>
                    <span className='text-danger'>{errorList.current_password}</span>
                  </div>
                  <div className="form-group position-relative changepass">
                    <label className="required" htmlFor="password">
                      New password:
                    </label>
                    <input
                      type={showNewPassword ? 'text' : 'password'}
                      className="form-control square"
                      name="password" id="password"
                      placeholder="New password"
                      minLength={6}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onBlur={() => confirmPass()} />
                    <div className='position-absolute eywshowhide'>

                      <button className="border-0 passwordshoingbtn"
                        type='button' onClick={() => setShowNewPassword(!showNewPassword)}>
                        {showNewPassword ?
                          <img src={viewPass} alt="" />
                          :
                          <img src={hidePass} alt="" />
                        }
                      </button>
                    </div>
                    <span className='text-danger'>{errorList.password}</span>
                  </div>
                  <div className="form-group position-relative changepass">
                    <label className="required" htmlFor="password_confirmation">
                      Password confirmation:
                    </label>
                    <input
                      type={showConfirmPassword ? 'text' : 'password'}
                      className="form-control square"
                      name="password_confirmation"
                      id="password_confirmation"
                      placeholder="Password confirmation"
                      value={passwordConfirm}
                      minLength={6}
                      onChange={(e) => { setPasswordConfirm(e.target.value); }}
                      onKeyUp={() => confirmPass()}
                    />
                    <div className='position-absolute eywshowhide'>

                      <button className="border-0 passwordshoingbtn"
                        type='button' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                        {showConfirmPassword ?
                          <img src={viewPass} alt="" />
                          :
                          <img src={hidePass} alt="" />
                        }
                      </button>
                    </div>
                    {errorList.password_confirmation ? (
                      <span className='text-danger'>{errorList.password_confirmation}</span>
                    ) : (
                      <span className='text-danger'>{confirmPassErr}</span>
                    )}
                  </div>
                  <div className="col-md-12">
                    <button type="submit" className="btn btn-fill-out submit">
                      Change password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default ChangePassword;