import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import TopNewsData from "../store/layoutData/TopNewsData";
import { useSelector } from "react-redux";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: "none", background: "black" }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: "none", background: "black" }} onClick={onClick} />;
}

const NewsFlash = () => {
  const { TopNewsData } = useSelector((state) => state.UserData);
  const [newses, setNewses] = useState();
  useEffect(() => {
        if(TopNewsData){
          setNewses(TopNewsData);
        }
  }, []); 

  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    beforeChange: function (currentSlide, nextSlide) {
      /*  console.log("before change", currentSlide, nextSlide); */
    },
    afterChange: function (currentSlide) {
      /* console.log("after change", currentSlide); */
    },
  };
  return (
    <div className="text-center">
      <ul>
        <Slider {...settings}>
            {newses && newses.map((news,index)=>{
              return(
                <div  key={index}>
                <div style={{ marginBottom: "60px" }}>
                  <li>
                    {/* <i className="fi-rs-bell d-inline-block mr-5"></i> */}
                    {news.news_image && (
                    <img className="d-inline-block mr-5" style={{maxWidth:'20px', maxHeight:'10px'}} src={news.news_image} alt={'news logo'}/>
                    
                    )} &nbsp;
                    <span className="d-inline-block">
                      <b className=""> {news.title} </b>
                    </span> &nbsp;
                    {news.url && news.button_name &&
                    (<Link className="active d-inline-block" to={news.url}>
                    {news.button_name}
                    </Link>)
                    }
                  </li>
                </div>
                </div>
              );
            })}

          {/* <div>
            <div style={{ marginBottom: "60px" }}>
              <li>
                <i className="fi-rs-asterisk d-inline-block mr-5"></i>
                <span className="d-inline-block">
                  <b className="text-danger">Supper Value Deals</b> - Save more with coupons
                </span>
              </li>
            </div>
          </div>

          <div>
            <div style={{ marginBottom: "60px" }}>
              <li>
                <i className="fi-rs-angle-double-right d-inline-block mr-5"></i>
                <span className="d-inline-block">Get great devices up to 50% off&nbsp;</span>
                <Link className="active d-inline-block" to="/products">
                  View details
                </Link>
              </li>
            </div>
          </div> */}
        </Slider>
      </ul>
    </div>
  );
}

export default NewsFlash;