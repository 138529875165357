import React, { useRef } from "react";
import axios from "axios";
import { Link,useNavigate } from "react-router-dom";
import ProductBoxes from "../components/ProductBoxes";
import Swal from "sweetalert2";
import Layout from "../layouts/Layout";
import { useState } from "react";
import { useEffect } from "react";
import { useCart } from "react-use-cart";
import { useDispatch, useSelector } from "react-redux";
import { decrement } from "../store/Counter";
import Skeleton from "react-loading-skeleton";
import emptyCart from '../assets/images/empty-cart.svg';
import LoadingBar from "react-top-loading-bar";

const Wishlist = () => {
  useEffect(() => {
    document.title = "Ultimate Organic Life - Wishlist";
    window.scrollTo(0, 0);
  }, []);
  let navigate = useNavigate();
  const { addItem } = useCart();
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [wpid, setWpid] = useState();
  const { wishlistCount } = useSelector((state) => state.wishlistCount);
  const skeltonData = [];
  if(wishlistCount>0){
    var i = 0;
    for(i=0; i< wishlistCount; i++){
      skeltonData.push(<tr className="pt-30">
      <td className="image product-thumbnail">
      <Skeleton inline={true} width={78} height={78} />
      </td>
      <td className="product-des product-name">
        <Skeleton inline={true} width={200} className="product-name mb-10 text-heading" />
      </td>
      <td data-title="Price" className="price">
        <h5 className="text-body"> <Skeleton inline={true} width={'50%'} /> </h5>{" "}
      </td>
      <td data-title="Stock" className="pl-30">
        <h5 className="text-success"> <Skeleton inline={true} width={'25%'} /> </h5>{" "}
      </td>
      <td data-title="Action" className="" style={{ whiteSpace: 'nowrap' }}>
      <Skeleton className="ms-4" inline={true} width={'25%'} />
        {" "}
      </td>
      <td data-title="Remove" className="action">
      <Skeleton className="ms-4" inline={true} width={'25%'} />
      </td>
    </tr>);
    }

  }
  const dispatch = useDispatch();
  const [wishlists, setWishlists] = useState();
  var customer_id;
  if (localStorage.getItem("user")) {
    var obj = JSON.parse(localStorage.getItem("user"));
    customer_id = obj.customer_id;
    // console.log(obj);
  }
  let loadCheck = 0;
  useEffect(() => {
    ref.current.continuousStart();
    if(loadCheck!=1 && customer_id && !wishlists){
      loadCheck=1;
      axios.get("get-wishlist/" + customer_id)
      .then(resp => {
        // console.log(resp.data);
        if(resp.data.success===true){
          ref.current.complete();
          setWishlists(resp.data.data);
          // if(wishlists.length<=0){
          // }
        }
        else if(resp.data.code===401 || resp.data.message === "Authorization token invalid, You cannot proceed"){
          navigate("/login?q=You%20Have%20To%20Login%20Again",{ replace: true });
        }
      })
      .catch((err) => {
        ref.current.complete();
        console.log(err);
      });
      // setReload(false);
    }
  }, [customer_id, wishlists]);

  // console.log(wishlists);
  const removeFromWishlist = (wID) => {
    ref.current.continuousStart();
    if(wID){
      setWpid(wID);
      axios.get("delete-wishlist/" + wID)
      .then(resp => {
        if(resp.data.success===true){
          ref.current.complete();
          setReload(true);
          setWishlists(null);
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'Product removed from wishlist',
            showConfirmButton: false,
            timer: 800
          })
          navigate('/wish-list',{ replace: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }

  return (
    <div>
      <LoadingBar
        color='#0098b8' 
        ref={ref}
        // progress={progress}
        // onLoaderFinished={() => setProgress(0)}
      />
        <Layout>
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div itemScope="itemscope" itemType="" className="breadcrumb">
              <Link to="/" itemProp="item" title="Home">
                Home
                <meta itemProp="name" content="Home" /> <meta itemProp="position" content="1" />
              </Link>{" "}
              <span></span>
              Wishlist
              <meta itemProp="name" content="Shopping Cart" /> <meta itemProp="position" content="2" />
            </div>
          </div>
        </div>
        <div className="mb-80 mt-50 section--shopping-cart">
            {wishlistCount>0 ? (
              <>
              <div className="row">
                <div className="col-lg-8 mb-40">
                  <h1 className="heading-2 mb-10">Your Wishlist</h1>{" "}
                  <div className="d-flex justify-content-between">
                    <h6 className="text-body">
                      There are <span className="text-brand">{wishlistCount && wishlistCount}</span> products in your wishlist
                    </h6>
                  </div>
                </div>
              </div>{" "}
              {/* <form method="post" action="#"> */}
              <div className="row">
                <div className="col-lg-12">
                  <div className="table-responsive shopping-summery">
                    <table className="table table-wishlist table--cart">
                      <thead>
                        <tr className="main-heading">
                          <th scope="col" colSpan="2" className="start pl-30">
                            Product
                          </th>
                          <th scope="col">Price</th>
                          <th scope="col" className="pl-30">Stock</th>
                          <th scope="col" className="pl-30">Action</th> 
                          <th scope="col" className="end pl-30">Remove&nbsp;&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                      {wishlists ? wishlists.map((wishlist, key) => {
                          return (
                            <tr key={key} className="pt-30">
                              <td className="image product-thumbnail ps-3">
                                <img src={wishlist.product_image.small} alt="Product" />
                              </td>
                              <td className="product-des product-name" style={{paddingLeft:'1rem'}}>
                                <h6 className="mb-5">
                                  <Link to={"/products/"+wishlist.product_slug} className="product-name mb-10 text-heading">
                                    {wishlist.product_name}
                                  </Link>
                                </h6>
                                {/* <p className="mb-0"> <small>(Weight: 4KG)</small></p> */}
                              </td>
                              <td data-title="Price" className="price">
                                <h5 className="text-body"> ৳{wishlist.product_final_price} </h5>{" "}
                                {/* <small>
                                    <del>$90.00</del>
                                  </small> */}
                              </td>
                              <td data-title="Stock" className="pl-30">
                                {Number(wishlist.product_stock)<1 ?
                                <h5 className="text-warning"> Out Of Stock </h5>
                                 :
                                <h5 className="text-success"> In Stock </h5>
                                 }{" "}
                                {/* <small>
                                    <del>$90.00</del>
                                  </small> */}
                              </td>
                              <td data-title="Action" className="" style={{ whiteSpace: 'nowrap' }}>
                              <div
                            className="add-cart"
                            onClick={() => {
                              if(Number(wishlist.product_stock)<1){
                                Swal.fire({
                                  position: 'top-end',
                                  icon: 'warning',
                                  title: 'Product is out of stock!'
                                });
                              }
                              else{
                                addItem({
                                  id: wishlist.product_id,
                                  product_id: wishlist.product_id,
                                  name: wishlist.product_name,
                                  price: Number(wishlist.product_final_price).toFixed(2),
                                  image: wishlist.product_image.small,
                                });
                              }
  
                            }
                            }
                          >
                            <Link aria-label="Add To Cart" data-id="2" data-url="#" to="" className="btn add-to-cart-button add" onClick={() =>
                              Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'Product added to cart',
                                showConfirmButton: false,
                                timer: 800
                              })
                            }>
                              <i className="fi-rs-shopping-cart mr-5"></i>Add To Cart
                            </Link>
                          </div>
                                {" "}
                              </td>
                              <td data-title="Remove" className="action text-center remove-cart-button-mobile">
                                <a href="#" data-url="" className="text-body remove-cart-button" onClick={() =>{
                                  removeFromWishlist(wishlist.id);
                                  dispatch(decrement());
                                }
                                }>
                                  <i className="fi-rs-trash"></i>
                                </a>
                              </td>
                            </tr>
                          );
                        })
                        : (
                          wishlistCount>0 ? ( <>
                          {skeltonData}
                          
                          </> ) : ( <h4 className="mt-4 text-center p-2 text-warning">No products in your wishlist</h4> )
                        )
                        }
                      </tbody>
                    </table>
                  </div>{" "}
                  <div className="divider-2 mb-30"></div>{" "}
                  <div className="cart-action d-flex justify-content-between">
                    <Link to={"/"} className="btn ">
                      <i className="fi-rs-arrow-left mr-10"></i>Continue Shopping
                    </Link>
                  </div>{" "}
                </div>
                
              </div>
              {/* </form> */}
              </>
            ) : (
              <center>
              <img src={emptyCart} alt="f" width='400' />
              <h2>Your Wishlist is empty</h2>
              <p>Looks like you haven't added anything to your wishlist yet</p>
            </center>
            )}
          </div>

          {/* related product thakbe */}
          <div className="row mt-60" id="related-products">
            <div className="col-12">
              <h3 className="section-title style-1 mb-30">You may also like</h3>
            </div>
          </div>
        <div>
          {" "}
          <ProductBoxes productType="popular" />{" "}
        </div>
        </Layout>
    </div>
  );
}

export default Wishlist;
