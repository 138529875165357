import { createSlice } from "@reduxjs/toolkit";

export const CustomerAddressDataSlice = createSlice({
  name: "CustomerAddressData",
  initialState: {
    CustomerAddressData: null,
  },
  reducers: {
    setCustomerAddressData: (state, action) => {
      state.CustomerAddressData = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setCustomerAddressData } = CustomerAddressDataSlice.actions;

export default CustomerAddressDataSlice.reducer;

