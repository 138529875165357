import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store/Store.js';
import './assets/Css/loadingAnimation.css'
import 'react-loading-skeleton/dist/skeleton.css'
import axios from "axios";
import { CartProvider } from 'react-use-cart';
import { hydrate } from 'react-dom';
import 'react-lazy-load-image-component/src/effects/blur.css';

// axios.defaults.baseURL="http://192.168.100.11:8080/api/";
// axios.defaults.baseURL="http://127.0.0.1:8000/api/";
// // axios.defaults.baseURL="http://192.168.100.99:8000/api/";
// axios.defaults.headers.common["Accept"] = 'application/json';
// axios.defaults.headers.common["retry-after"] = 3600;
// var token = null;
// const { UserData } = useSelector((state) => state.UserData);
// if (localStorage.getItem("user") || UserData) {
//   var obj = JSON.parse(localStorage.getItem("user"));
//   if(UserData){
//     obj=UserData;
//   }
//   // console.log(obj);
//   token = obj.token;
//   axios.defaults.headers.common["Authorization"] = 'Bearer '+token;
//   // axios.defaults.headers.common["Authorization"] = token;
//   // axios.defaults.headers.common["bearerToken"] = token;
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
    <Provider store={store}>
      <CartProvider>
      <App />
      </CartProvider>
    </Provider>
    </Router>
  </React.StrictMode>
);

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(<React.StrictMode>
//     <Router>
//     <Provider store={store}>
//       <CartProvider>
//       <App />
//       </CartProvider>
//     </Provider>
//     </Router>
//   </React.StrictMode>, rootElement);
// } else {
//   root.render(<React.StrictMode>
//     <Router>
//     <Provider store={store}>
//       <CartProvider>
//       <App />
//       </CartProvider>
//     </Provider>
//     </Router>
//   </React.StrictMode>, rootElement);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
