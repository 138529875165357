import React from "react";
import { Link } from "react-router-dom";
const BestsellBanner = () => {
  
    return (
      /* <div className="col-xl-12 d-none d-xl-flex wow animate__ animate__fadeIn" style={{ visibility: "visible", animationName: "fadeIn" }}> */
      <div className="d-xl-flex wow animate__ animate__fadeIn">
        <div className="banner-img style-2" style={{ backgroundImage: 'URL("assets/images/Banner-3_1.png")' }}>
          <div className="banner-text text-center w-100">
            {/* <h2 className="mb-100">Bring nature into your home</h2> */}
            <h2 className="mb-200" />
            <br/>
            <center><Link to="/products" className="btn btn-xs">
              Shop now
              <i className="fi-rs-arrow-small-right"></i>
            </Link></center>
          </div>
        </div>
      </div>
    )
}

export default BestsellBanner;
