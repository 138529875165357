import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../layouts/Layout";
// import ReactCaptcha from 'modern-react-captcha';
import ReactCaptcha from 'react-google-recaptcha';

// import Parser from 'html-react-parser';
import RefreshIcon from '../assets/images/Icon/refresh-icon.png';
import '../assets/Css/contact.css';
import Swal from "sweetalert2";
import axios from "axios";

const Contact = () => {
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //     setLoading(true);
  //     setTimeout(() => {
  //         setLoading(false);
  //     }, 1000);
  // }, []);
  useEffect(() => {
    document.title = "Ultimate Organic Life | Contact";
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const [captchaError, setCaptchaError] = useState(0);

  const handleSuccess = () => {
    // console.log('Captcha matched!');
    setCaptchaError(1);
  };

  const handleFailure = () => {
    // console.log('captcha not matched');
    setCaptchaError(2);
  };

  const handleCaptchaChange = (value) => {
    if (value) {
      console.log('CAPTCHA succeeded:', value);
      handleSuccess();  // Call your success handler
    } else {
      console.log('CAPTCHA failed');
      handleFailure();  // Call your failure handler
    }
  };

  const [errorList, setError] = useState([]);
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [address, setAddress] = useState("");
  let [phone, setPhone] = useState("");
  let [subject, setSubject] = useState("");
  let [message, setMessage] = useState("");

  const handleContactUs = () => {
    if (captchaError === 0) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Fillup The Captcha to Proceed',
        // showConfirmButton: false,
        // timer: 2000
      });
    }
    else if (captchaError === 2) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Correctly Fillup The Captcha to Proceed',
        // showConfirmButton: false,
        // timer: 2000
      });
    }
    else {
      axios
        .post("contact-us", {
          name: name,
          email: email,
          address: address,
          phone: phone,
          subject: subject,
          message: message
        })
        .then(function (resp) {
          if (resp.data.success) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: resp.data.message,
              // showConfirmButton: false,
              // timer: 2000
            });
            setName('');
            setEmail('');
            setAddress('');
            setPhone('');
            setSubject('');
            setMessage('');
          }
          else {
            setError(resp.data.message);
          }
        })
        .catch((err) => {
          setError(err.response.data.errors);
        });
    }
  }

  return (
    <div>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <Layout>
        <div className="page-header breadcrumb-wrap">
          <div className="breadcrumb" itemScope itemType="">
            <Link to="/" itemProp="item" title="Home">
              Home
              <meta itemProp="name" content="Home" />
              <meta itemProp="position" content="1" />
            </Link>
            <span></span> Contact
            <meta itemProp="name" content="Account information" />
            <meta itemProp="position" content="2" />
          </div>
        </div>
        <section className="mt-10">
          <section className="pb-50 ">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-xl-8 col-lg-10 m-auto">
                  <div className="contact-from-area  padding-20-row-col wow tmFadeInUp animated animated" style={{ visibility: "visible" }}>
                    <h3 className="mb-10 text-center">Drop Us a Line</h3>{" "}
                    <p className="text-muted mb-30 text-center font-sm">Contact Us For Any Questions</p>{" "}
                    {/* <form method="POST" action="#" acceptCharset="UTF-8" className="contact-form-style text-center contact-form"> */}
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="input-style mb-20">
                          <input name="name" value={name} placeholder="Name*" type="text" onChange={(e) => setName(e.target.value)} required />
                          <span className='text-danger ps-1'>{errorList.name}</span>
                        </div>
                      </div>{" "}
                      <div className="col-lg-6 col-md-6">
                        <div className="input-style mb-20">
                          <input type="email" name="email" value={email} placeholder="Email*" onChange={(e) => setEmail(e.target.value)} required />
                          <span className='text-danger ps-1'>{errorList.email}</span>
                        </div>
                      </div>{" "}
                      <div className="col-lg-6 col-md-6">
                        <div className="input-style mb-20">
                          <input name="address" value={address} placeholder="Address*" type="text" onChange={(e) => setAddress(e.target.value)} required />
                          <span className='text-danger ps-1'>{errorList.address}</span>
                        </div>
                      </div>{" "}
                      <div className="col-lg-6 col-md-6">
                        <div className="input-style mb-20">
                          <input name="phone" value={phone} placeholder="Phone*" type="tel" onChange={(e) => setPhone(e.target.value)} required />
                          <span className='text-danger ps-1'>{errorList.phone}</span>
                        </div>
                      </div>{" "}
                      <div className="col-lg-12 col-md-12">
                        <div className="input-style mb-20">
                          <input name="subject" value={subject} placeholder="Subject*" type="text" onChange={(e) => setSubject(e.target.value)} required />
                          <span className='text-danger ps-1'>{errorList.subject}</span>
                        </div>
                      </div>{" "}
                      <div className="col-lg-12 col-md-12">
                        <div className="textarea-style mb-20">
                          <textarea name="content" placeholder="Message*" value={message} onChange={(e) => setMessage(e.target.value)} required />
                          <span className='text-danger ps-1'>{errorList.message}</span>
                        </div>
                      </div>{" "}

                      <div className="col-lg-12 col-md-12">
                        <div className="input-style">

                          <div style={{ backgroundColor: 'white', padding: '10px' }}>
                            <ReactCaptcha
                              sitekey="6LcXyVodAAAAANMxStmp6FfFqo2mjnog7bHH1QWq"  // Replace with your actual site key
                              onChange={handleCaptchaChange}
                              theme="white"  // Set the theme to dark, which fits with your bgColor='black'
                            />
                          </div>

                        </div>

                        {captchaError === 2 && (<span className="text-danger">Captcha does not match</span>)}
                      </div>{" "}
                      <div className="col-lg-12 col-md-12">
                        <button type="submit" className="submit submit-auto-width mt-30" onClick={() => handleContactUs()}>
                          Send message
                        </button>
                      </div>
                    </div>{" "}
                    <div className="form-group text-left">
                      <div className="contact-message contact-success-message mt-30" style={{ display: "none" }}></div>{" "}
                      <div className="contact-message contact-error-message mt-30" style={{ display: "none" }}></div>
                    </div>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </section>{" "}
          <p></p>

          <p></p>
          <div style={{ height: "400px", width: " 100%", position: "relative", textAlign: "right" }}>
            <div style={{ height: "400px", width: "100%", overflow: "hidden", background: "none !important" }}>
              <iframe
                title="Map"
                width="100%"
                height="500"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d456.4420935532579!2d90.43296898907721!3d23.763893603827906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c7f56a95a617%3A0x7c3d0ad7fd7cec31!2sSiraj%20Convention%20Center!5e0!3m2!1sen!2sbd!4v1663095655374!5m2!1sen!2sbd"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
              ></iframe>

            </div>
          </div>
          <p></p>
          <div className="mt-4 pb-50">
            <div className="row">
              <div className="col-12">
                <a
                  href="https://goo.gl/maps/429WsbudmY7Qyq8aA"
                  className="btn btn-outline btn-sm btn-brand-outline font-weight-bold text-brand bg-white text-hover-white border-radius-5 btn-shadow-brand hover-up align-items-center"
                  target="_blank"
                >
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE7UlEQVR4nO1aaYgcVRAuTxSPiIoaUVTwiAZEfwjiAR4gaLyP+McDFIwawvojB+iPdVEwBA9CdqeqJ0JQk/wYQhSNXdW7rqtkRX8EPNHoBvWHaDwQjNGocR2p917PdKdnerrnSivzQcPw5r169b2qV++oB9BLeNsOAeSFgLwBUD4H5N3mI9luy3ghVCqHQqGB/i2AsgNIqqmf1tG6hUO1egCgrALkf5yyHwLJEkD/PHgxOMJ8ZZ4PyEOA8lGdEK+E4eEDoTBAfsop9wcgL0pVTv/z5CFTNyRTCJT4xgiJKzK307ohGU9uhv0KnbTEM25kF+Vuj/7DdhB4xgSJ/AJkApA/Ay+4F4anDs4vIJTDNzlFPki4U0muB+JJG7H4V/Pb4wWxOpXKQUD8sR2I4PZMfar1dJ4hvwnxKMIzgHJPW4RQXnJylsTKiZ9MiVhPxOvKI06P9S0IXALE0zFZMRL1318YQjpKWWGjUxXGxs+NWyKcM7IUPJ5rPuJl9TkRsUyZ57v625sQmAckmyORbmeSiFpB3WtfQiW+uyWhUTnTuoxUTXgNoSa3I7800QZ5uetjslb2/CtHufq74vK3nAQkBMR7XRt1zxEYnToySSREI0K6KjciVPIvBuJNQDJbq+tNzKkrK7vcqM9NEFkzfnJC6Wcqh7uy3+rEeMTtBpTAXkBBQyxEUyJphNTk5N9lJjbK1kj574Dyp/ldDs5omwjy6U7eN0C8GIi/j/SxGUp8TkJOSyLphEJL/QjIj0MpOAFQ3rJKB9fWO+FJN5LLGiiwIuFaHi9IBgWeNpO7GTIT2ZeQupmxDD9oXKE2mrIqEYnqiukCudxYwVpiRc2C0cmO/HTNhTR4ZFkccxNphZLc5oh8EitXYlnDL7pNZim4KnO/XSeiEzOclNEQXLOMupmJNo0XxDH/fGeNn3KtY10nYoTyeqfMSBttn3Vrg5evXS+IeME1zmV+iM2flu0m5tQiXDm4YP8T0bNIuMJrMMisjFnpdQC25u6zJ0QUeny1Su3IdIzVnQDKtzaCyZXFIWJ3sZ86fx/KoMiwq8tt9dczIgr0r3Nyf4Y1E8c1rbf2jRPd3JiFkn9h8YgodIStbEpRYqOzxoa2+6FeE9F9EfIeewnhX534X1ftcK81JqcWl4iC+FEn/2uzYIZYN3WM3Ria6LYYOgH1g4i9oHvfKVxusHBOd3wFRP0gotD7LN3mGzfy7zcXE3Ze7AYcP6tj+dQvIqYzPVsY5XXO7LEbQ7mvO7Klj0RMh/xCZOe7sXtypc9EdAUnedd80XP9f45Ir0ADIgUDDSxSMNDAIgUDDSxSMNDAIgUD/W8t4nWYQ2x16a3nkHVTh0E3QXxrnUgsz6EZ1Q4JpaYheCegPAZr5diOCGBwKSC/E5H7dpOO2yCUlhgy2ddIx3qRjfwcjG45LRcBT+YB8ssR+d/ZRwlRPc0rBL7DuUJY8Sug4IFUQo3aIX/ZsJ3nXwYoFUD529WdBeLXwPMvSifw6vGAsrqWQ7SPc1bCav/o5o2yEspDoFHyVBULj73hRQQFN5j74/hhTDNbvzj5f5nb+mgOsSXSXK5R9jdvOltR9k8xWarwJp6MrG1Acqd5CRGmn83dGG+C8utn55KfwULZXS97kmioftcVCxDvQYkv70h+DNFwql+3w7VCw7MOjD4p0eccOR7Y/AtOoJ8QnZzxEgAAAABJRU5ErkJggg=="
                    width={12} />&nbsp;<span style={{ marginTop: '-10px' }}>View map</span>
                </a>
                <p className="mt-4">We would love to answer any inquiries and hear your feedback and suggestions! Feel free to contact us for anything related to Ultimate Organic Life! Here is our contact information below- </p>
                <h4 className="mb-10 fw-bold">Customer Support</h4>
                <abbr title="Helpline">Helpline:</abbr> Call our helpline number anytime you want. We have 7 Days of customer support. Helpline number: +88 096 1366 4444
                <br /> <abbr title="Chat">Chat: </abbr>Knock on our <a href="https://www.facebook.com/ultimateorganiclife" target="_blank" className="">Facebook</a> page and our representative will reach you as soon as possible.
                <br /> <abbr title="E-mail">E-mail: </abbr> <a href="mailto: hello@ultimateorganiclife.com" className="">hello@ultimateorganiclife.com</a>
                <br /> <abbr title="Youtube">Youtube: </abbr> <a href="https://www.youtube.com/@DrJahangirKabir" target="_blank" className="">https://www.youtube.com/@DrJahangirKabir</a>
                <br />{" "}
                <h4 className="mt-4 mb-10 fw-bold">General Inquiries</h4>
                For any inquiries, product information, scopes of partnership, collaboration, etc., feel free to contact us via e-mail: <a href="mailto: hello@ultimateorganiclife.com" className="">hello@ultimateorganiclife.com</a>
                <br />{" "}
                <h4 className="mt-4 mb-10 fw-bold">Visit Us</h4>
                If you want to visit the office of Ultimate Organic Life, come to this <a
                  href="https://www.google.com/maps/dir/%E0%A6%B8%E0%A6%BF%E0%A6%B0%E0%A6%BE%E0%A6%9C+%E0%A6%95%E0%A6%A8%E0%A6%AD%E0%A7%87%E0%A6%A8%E0%A6%B6%E0%A6%A8+%E0%A6%B8%E0%A7%87%E0%A6%A8%E0%A7%8D%E0%A6%9F%E0%A6%BE%E0%A6%B0+-+%E0%A6%86%E0%A6%AB%E0%A6%A4%E0%A6%BE%E0%A6%AC%E0%A6%A8%E0%A6%97%E0%A6%B0,+Plot+No-39,+%E0%A6%86%E0%A6%AB%E0%A6%A4%E0%A6%BE%E0%A6%AC+%E0%A6%A8%E0%A6%97%E0%A6%B0+%E0%A6%AA%E0%A7%8D%E0%A6%B0%E0%A6%A7%E0%A6%BE%E0%A6%A8+%E0%A6%B8%E0%A7%9C%E0%A6%95,+%E0%A6%A2%E0%A6%BE%E0%A6%95%E0%A6%BE+1212/''/@23.7640629,90.4327885,19z/data=!4m13!4m12!1m5!1m1!1s0x3755c7f56a95a617:0x7c3d0ad7fd7cec31!2m2!1d90.4327885!2d23.7640629!1m5!1m1!1s0x3755c7f56a95a617:0x7c3d0ad7fd7cec31!2m2!1d90.4327885!2d23.7640629?entry=ttu"
                  className=""
                  target="_blank"
                >
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE7UlEQVR4nO1aaYgcVRAuTxSPiIoaUVTwiAZEfwjiAR4gaLyP+McDFIwawvojB+iPdVEwBA9CdqeqJ0JQk/wYQhSNXdW7rqtkRX8EPNHoBvWHaDwQjNGocR2p917PdKdnerrnSivzQcPw5r169b2qV++oB9BLeNsOAeSFgLwBUD4H5N3mI9luy3ghVCqHQqGB/i2AsgNIqqmf1tG6hUO1egCgrALkf5yyHwLJEkD/PHgxOMJ8ZZ4PyEOA8lGdEK+E4eEDoTBAfsop9wcgL0pVTv/z5CFTNyRTCJT4xgiJKzK307ohGU9uhv0KnbTEM25kF+Vuj/7DdhB4xgSJ/AJkApA/Ay+4F4anDs4vIJTDNzlFPki4U0muB+JJG7H4V/Pb4wWxOpXKQUD8sR2I4PZMfar1dJ4hvwnxKMIzgHJPW4RQXnJylsTKiZ9MiVhPxOvKI06P9S0IXALE0zFZMRL1318YQjpKWWGjUxXGxs+NWyKcM7IUPJ5rPuJl9TkRsUyZ57v625sQmAckmyORbmeSiFpB3WtfQiW+uyWhUTnTuoxUTXgNoSa3I7800QZ5uetjslb2/CtHufq74vK3nAQkBMR7XRt1zxEYnToySSREI0K6KjciVPIvBuJNQDJbq+tNzKkrK7vcqM9NEFkzfnJC6Wcqh7uy3+rEeMTtBpTAXkBBQyxEUyJphNTk5N9lJjbK1kj574Dyp/ldDs5omwjy6U7eN0C8GIi/j/SxGUp8TkJOSyLphEJL/QjIj0MpOAFQ3rJKB9fWO+FJN5LLGiiwIuFaHi9IBgWeNpO7GTIT2ZeQupmxDD9oXKE2mrIqEYnqiukCudxYwVpiRc2C0cmO/HTNhTR4ZFkccxNphZLc5oh8EitXYlnDL7pNZim4KnO/XSeiEzOclNEQXLOMupmJNo0XxDH/fGeNn3KtY10nYoTyeqfMSBttn3Vrg5evXS+IeME1zmV+iM2flu0m5tQiXDm4YP8T0bNIuMJrMMisjFnpdQC25u6zJ0QUeny1Su3IdIzVnQDKtzaCyZXFIWJ3sZ86fx/KoMiwq8tt9dczIgr0r3Nyf4Y1E8c1rbf2jRPd3JiFkn9h8YgodIStbEpRYqOzxoa2+6FeE9F9EfIeewnhX534X1ftcK81JqcWl4iC+FEn/2uzYIZYN3WM3Ria6LYYOgH1g4i9oHvfKVxusHBOd3wFRP0gotD7LN3mGzfy7zcXE3Ze7AYcP6tj+dQvIqYzPVsY5XXO7LEbQ7mvO7Klj0RMh/xCZOe7sXtypc9EdAUnedd80XP9f45Ir0ADIgUDDSxSMNDAIgUDDSxSMNDAIgUD/W8t4nWYQ2x16a3nkHVTh0E3QXxrnUgsz6EZ1Q4JpaYheCegPAZr5diOCGBwKSC/E5H7dpOO2yCUlhgy2ddIx3qRjfwcjG45LRcBT+YB8ssR+d/ZRwlRPc0rBL7DuUJY8Sug4IFUQo3aIX/ZsJ3nXwYoFUD529WdBeLXwPMvSifw6vGAsrqWQ7SPc1bCav/o5o2yEspDoFHyVBULj73hRQQFN5j74/hhTDNbvzj5f5nb+mgOsSXSXK5R9jdvOltR9k8xWarwJp6MrG1Acqd5CRGmn83dGG+C8utn55KfwULZXS97kmioftcVCxDvQYkv70h+DNFwql+3w7VCw7MOjD4p0eccOR7Y/AtOoJ8QnZzxEgAAAABJRU5ErkJggg=="
                    width={12} />&nbsp;<span style={{ marginTop: '-10px' }}>address</span>
                </a>: <br />

                <span className="fw-bold">House: 29/31, Noor Tower, Block: D, Road: 01, Sector: 02, Jahurul Islam City, Aftabnagar, Badda , Dhaka 1212</span>
                <br />
                We’d love to discuss your inquiries, proposals, or other interests!

                <br />{" "}
                <h4 className="mt-4 mb-10 fw-bold">Feedback & Suggestions</h4>
                We’d love to hear from you! Drop a line, feedback, or suggestion, or ask any question on our website!
                <br />{" "}
              </div>{" "}
            </div>
          </div>{" "}
          <hr /> <p></p>
          <p></p>
        </section>
      </Layout>
    </div>
  );
};

export default Contact;
