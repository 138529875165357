import React from "react";
import { useEffect } from "react";
import BestsellBanner from "../components/BestsellBanner";
import BestSellProducts from "../components/BestSellProducts";
import HomeBannerSlider from "../components/HomeBannerSlider";
import ProductBoxes from "../components/ProductBoxes";
import SEO from "../components/Seo";
import ShopByCategories from "../components/ShopByCategories";
import Layout from "../layouts/Layout";
import seoLogo from "../assets/images/logo/logo_1200-630.png";
import { useSelector } from "react-redux";

const Home = () => {

  const { HeaderData } = useSelector((state) => state.HeaderData);

  return (
    <div>
      <SEO title={HeaderData && HeaderData.meta_title ? HeaderData.meta_title : 'Ultimate Organic Life | Home'} meta_title={'Ultimate Organic Life'} 
      meta_description={HeaderData && HeaderData.meta_description ? HeaderData.meta_description : 'Organic Grocery Store'} keywords={HeaderData && HeaderData.meta_keywords ? HeaderData.meta_keywords : 'Organic, Ecommerce'}
      name={'Ultimate Organic Life'} type={'ecommerce'} meta_image={seoLogo} />
      <Layout>
        <HomeBannerSlider />

        <h3 className="p-3 mt-2">
          Shop By Categories
        </h3>
        <ShopByCategories />

        <h3 className="p-3 mt-2">
          Popular Products
        </h3>
        <ProductBoxes productType="popular" />

        <h3 className="p-3 mt-2">Daily Best Sells</h3>
        <div className="row">
          <div className="col-md-3 d-sm-none d-md-block d-lg-block">
            <BestsellBanner />
          </div>
          <div className="col-md-9 col-sm-12 p-0">
            <BestSellProducts />
          </div>
        </div>

      </Layout>
    </div>

  );

  
};


export default Home;
