// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog_image_skelton{
    border-radius: 15px !important;
    overflow: hidden !important;
    width: 100% !important;
    max-height: 40rem !important;
}

@media screen and (max-width: 768px) {
    .blog_image_skelton{
        width: 100% !important;
        max-height: 20rem !important;
    }
  }

.blog_description h1, .blog_description h2, .blog_description h3, .blog_description h4, .blog_description h5, .blog_description h6, .blog_description p{
    font-size: unset;
    line-height: unset;
    margin-bottom: unset;
}
.blog_image_list{
    display: flex;
    align-items: center;
}
.blog_description li{
    list-style: disc;
}`, "",{"version":3,"sources":["webpack://./src/assets/Css/blog.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,2BAA2B;IAC3B,sBAAsB;IACtB,4BAA4B;AAChC;;AAEA;IACI;QACI,sBAAsB;QACtB,4BAA4B;IAChC;EACF;;AAEF;IACI,gBAAgB;IAChB,kBAAkB;IAClB,oBAAoB;AACxB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".blog_image_skelton{\n    border-radius: 15px !important;\n    overflow: hidden !important;\n    width: 100% !important;\n    max-height: 40rem !important;\n}\n\n@media screen and (max-width: 768px) {\n    .blog_image_skelton{\n        width: 100% !important;\n        max-height: 20rem !important;\n    }\n  }\n\n.blog_description h1, .blog_description h2, .blog_description h3, .blog_description h4, .blog_description h5, .blog_description h6, .blog_description p{\n    font-size: unset;\n    line-height: unset;\n    margin-bottom: unset;\n}\n.blog_image_list{\n    display: flex;\n    align-items: center;\n}\n.blog_description li{\n    list-style: disc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
