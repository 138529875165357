import React, { useState, useEffect } from 'react';
import ProductBoxes from '../components/ProductBoxes';
import { Link } from 'react-router-dom';
import Layout from '../layouts/Layout';

const AllProducts = () => {
  //All products page :(
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Ultimate Organic Life | Products";
  }, []);

  return (
    <div>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <Layout>
        <div className="page-header breadcrumb-wrap">
          <div className="breadcrumb" itemScope itemType="http://schema.org/BreadcrumbList">
            <Link to="/" itemProp="item" title="Home">
              Home
            </Link>
            <span></span> Products
            <span></span>
          </div>
        </div>
        <br />
        <ProductBoxes productType="get-products"/>
        <br />
      </Layout>

    </div>
  );
}

export default AllProducts;