import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Layout from "../layouts/Layout";
import Parser from 'html-react-parser';
import Skeleton from "react-loading-skeleton";

const Certification = () => {
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //     setLoading(true);
  //     setTimeout(() => {
  //         setLoading(false);
  //     }, 500);
  // }, []);

  useEffect(() => {
    document.title = "Ultimate Organic Life | Privacy Policy";
    window.scrollTo(0, 0);
  }, []);
  let loadCheck = 0;
  const [privacyPolicy, setPrivacyPolicy] = useState();
  useEffect(() => {
    if (loadCheck === 0) {
      loadCheck = 1;
      axios.get("privacy-policy")
        .then(resp => {
          // console.log(resp.data.description);
          setPrivacyPolicy(resp.data.description);
        });
    }
  }, []);

  return (
    <div>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <Layout>
        <div className="page-header breadcrumb-wrap">
          <div className="breadcrumb">
            <Link to="/" itemProp="item" title="Home">
              Home
            </Link>
            <span></span> Certification
            <span></span>
          </div>
        </div>
        <br />
        <div className="row justify-content-center">
          <div className="col-12 d-flex justify-content-center pt-1 pb-4">
            <img style={{ width: '600px' }} src="/assets/images/certificate.jpg" alt="Certificate" />
          </div>
        </div>


      </Layout>
    </div>
  );
};

export default Certification;
