import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link,useLocation } from "react-router-dom";
import successLogo from '../../assets/images/Icon/GreenBGtick.svg';

const PaymentSuccess = () => {
  const search = useLocation().search;
  const queryParam = new URLSearchParams(search);
  const [loading, setLoading] = useState(false);
// console.log(queryParam.get('tran_id'));
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  useEffect(() => {
    document.title = "Ultimate Organic Life - Payment Success";
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="checkout-page">
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <div className="checkout-content-wrap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row justify-content-center">
                <div className="col-lg-7 col-md-6 col-12 ">
                  <div className="checkout-logo">
                    <div className="container">
                      <Link to="/" title="Ultimate">
                        <img src={"/assets/images/logo/Original_logo.png"} className="img-fluid" width="150" alt="Ultimate Organic Ecommerce Logo"/>
                      </Link>
                    </div>
                  </div>
                  <hr/>
                    <div className="thank-you">
                      <center>
                        <img src={successLogo} height={64} width={64} alt="successlogo" className="img-fluid pe-1" style={{position: 'relative', top:'10px'}} />
                      <div className="d-inline-block">
                        <h4 className="thank-you-sentence">
                          Your order is successfully placed
                        </h4>
                        <p>Thank you for purchasing our products!</p>
                      </div>
                      </center>
                    </div>
                    <br/>
                    <br/>
                    <div className="order-customer-info pb-4">
                      <h6> Order & Transaction information</h6>
                      <p>
                        <span className="d-inline-block p-2">Transaction ID: &nbsp;</span>
                        <span className="order-customer-info-meta">{queryParam.get('tran_id')}</span>
                      </p>
                      <p>
                        <span className="d-inline-block p-2">Amount Paid:</span>
                        <span className="order-customer-info-meta">{queryParam.get('amount')}</span>
                      </p>
                      <p>
                        <span className="d-inline-block p-2">Order ID:</span>
                        <span className="order-customer-info-meta">{queryParam.get('order_id')}</span>
                      </p>
                      {/* <p> */}
                      {/* </p> */}
                      <p>
                        <span className="d-inline-block p-2">Payment method:</span>
                        <span className="order-customer-info-meta">SslCommerz</span>
                      </p>
                      <p>
                        <span className="d-inline-block p-2">Payment status:</span>
                        <span className="order-customer-info-meta" style={{textTransform: 'uppercase'}}><span className="label-success status-label">Completed</span></span>
                      </p>
                    </div>
                    <Link to="/" className="btn payment-checkout-btn"> <i class="fa fa-arrow-left" aria-hidden="true"></i> {'<- Continue shopping'} </Link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
