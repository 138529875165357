import React from 'react'
import Footer from './Footer';
import MobileMenu from './MobileMenu';
import Header from './Header';
import CookieConsent from "react-cookie-consent";
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";


const Layout = ({ children }) => {


  useEffect(() => {
    if (typeof window !== 'undefined') {
      const loadREVEChat = () => {
        window.$_REVECHAT_API ||
          (function (d, w) {
            var r = (window.$_REVECHAT_API = function (c) {
              r._.push(c);
            });
            w.__revechat_account = '10154695';
            w.__revechat_version = 2;
            r._ = [];
            var rc = d.createElement('script');
            rc.type = 'text/javascript';
            rc.async = true;
            rc.setAttribute('charset', 'utf-8');
            rc.src =
              (document.location.protocol === 'https:' ? 'https://' : 'http://') +
              'static.revechat.com/widget/scripts/new-livechat.js';
            var s = d.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(rc, s);
          })(document, window);
      };
  
      loadREVEChat();
    }
  }, []);

    return (
        <div>
            <MobileMenu />

            <div className='row justify-content-center site_width_mobile'>
                <div className='col-lg-11 col-xl-11 p-0 ps-lg-3 pe-lg-3 site_width'>
                  
                    <Header/>
                    
                    {children}

                    <Footer />
                </div>
            </div>
            {/* <div className=''>
            <CookieConsent buttonText="Allow Cookie"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#ffffff", fontSize: "13px", backgroundColor: '#000000', border: '1px solid #f1f1f1', marginRight: '10rem' }}><span className='p-lg-5 ms-lg-4'>Your experience on this site will be improved by allowing cookies <Link to={'/cookie-policy'} >Cookie Policy</Link></span>
                </CookieConsent>
            </div> */}
        </div>
    )
}
export default React.memo(Layout);