// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modern-react-captcha{
    display: flex;
    justify-content: space-between;
}
.modern-react-captcha__captchaText{
    margin: auto;
}
.modern-react-captcha__reloadBtn{
    border: none !important;
    background: none !important;
    padding: 1rem !important;
    
}
.modern-react-captcha__reloadBtn .contact-from-area .contact-form-style .modern-react-captcha__reloadBtn:hover {
    background-color: transparent !important;
}
.modern-react-captcha__reloadBtn:hover{
    /* border: none !important; */
    background: none !important;
    /* padding: 0 !important; */
    /* background-color: black; */
}
/* .contact-from-area .contact-form-style button {
    background-color: var(--color-heading);
    border: none;
    border-radius: 10px;
    background: none;
    color: #fff; 
    font-family: var(--font-heading), sans-serif;
    font-size: 17px; 
    font-weight: 500;
    padding: 20px 40px;
} */`, "",{"version":3,"sources":["webpack://./src/assets/Css/contact.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,YAAY;AAChB;AACA;IACI,uBAAuB;IACvB,2BAA2B;IAC3B,wBAAwB;;AAE5B;AACA;IACI,wCAAwC;AAC5C;AACA;IACI,6BAA6B;IAC7B,2BAA2B;IAC3B,2BAA2B;IAC3B,6BAA6B;AACjC;AACA;;;;;;;;;;GAUG","sourcesContent":[".modern-react-captcha{\n    display: flex;\n    justify-content: space-between;\n}\n.modern-react-captcha__captchaText{\n    margin: auto;\n}\n.modern-react-captcha__reloadBtn{\n    border: none !important;\n    background: none !important;\n    padding: 1rem !important;\n    \n}\n.modern-react-captcha__reloadBtn .contact-from-area .contact-form-style .modern-react-captcha__reloadBtn:hover {\n    background-color: transparent !important;\n}\n.modern-react-captcha__reloadBtn:hover{\n    /* border: none !important; */\n    background: none !important;\n    /* padding: 0 !important; */\n    /* background-color: black; */\n}\n/* .contact-from-area .contact-form-style button {\n    background-color: var(--color-heading);\n    border: none;\n    border-radius: 10px;\n    background: none;\n    color: #fff; \n    font-family: var(--font-heading), sans-serif;\n    font-size: 17px; \n    font-weight: 500;\n    padding: 20px 40px;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
