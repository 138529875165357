import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Layout from "../layouts/Layout";
import NotFoundImage from '../assets/images/404.png';

const NotFoundPage = () => {
  useEffect(() => {
    document.title = "Ultimate Organic Life | 404 Not Found";
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Layout>
        <center><main className="main page-404">
          <div className="page-content pt-50 pb-150">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-10 col-md-12 m-auto text-center">
                  <p className="mb-20"><img src={NotFoundImage} alt="Ultimate Organic Life" className="hover-up" />
                  </p>
                  <h2 className="display-2 mb-30">
                    Page Not Found
                  </h2>
                  <p className="font-lg text-grey-700 mb-30">
                    The link you clicked may be broken or the page may have been removed.<br /> visit the
                    <Link to="/"><span> Homepage</span></Link> or <Link to="/contact"><span>Contact us</span></Link> about the problem. </p>
      
                  <Link className="btn btn-default submit-auto-width font-xs hover-up mt-30" to="/"><i className="fi-rs-home mr-5"></i> Back To Home Page</Link>
                </div>
              </div>
            </div>
          </div>
        </main></center>
      </Layout>
    </div>
  );
};

export default NotFoundPage;
