import React from "react";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import '../assets/Css/menu.css'
import CoffeeTea from '../assets/images/category/icon-2.png'
import { useSelector } from "react-redux";

const Categories = (props) => {
  const { CategoryData } = useSelector((state) => state.CategoryData);
  const ref = useRef(null);
  const [toggle, setToggle] = useState(false);
  const [flag, setFlag] = useState(true);

  const { onClickOutside } = props;
  const [show, setShow] = useState(props.show);
  // console.log('sidebar '+show);
  useEffect(() => {
    // if(!show){
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          // setShow(false);
          onClickOutside && onClickOutside();
        }
      };
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };

    // }
  }, [onClickOutside,show]);

  // console.log(categories);

  return (
          <div ref={ref} className={show ? 'categories-dropdown-wrap categories-dropdown-active-large font-heading open' : 'categories-dropdown-wrap categories-dropdown-active-large font-heading'}>
          {/* <div ref={ref} className={'categories-dropdown-wrap categories-dropdown-active-large font-heading open'}> */}
            <div className="d-flex categories-dropdown-inner">
              {CategoryData &&
                <div className="">
                  <div>
                    <ul className="menu_list">
                      {(
                          CategoryData.map((category, index) => {
                            // if (index<(apiDetails.categories.length/2)) {
                            return (
                              <NavLink key={index} className="NavLink" to={"/product-categories/" + category.id + '/' + category.name} onClick={() => setToggle(!toggle)}>
                                {category.image ? (

                                  <li><img src={category.image} alt={category.name} width="30" height="30" />&nbsp;&nbsp;&nbsp;
                                    {category.name}</li>
                                ) : (<li><img src={CoffeeTea} alt={category.name} width="30" height="30" />&nbsp;&nbsp;&nbsp;
                                {category.name}</li>)}

                              </NavLink>

                            );
                          })
                          
                      )}
                    </ul>
                  </div>
                </div>
              }

            </div>
          </div>
  );
};
export default Categories;
