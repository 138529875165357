// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu_list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }

  .menu_div{
    /* max-width: 45rem !important; */
  }

  .footer_category{
    left: -5%;
  }

  .slick_slider_top_reduce{
    top: 24% !important;
  }`, "",{"version":3,"sources":["webpack://./src/assets/Css/menu.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;IACrC,wBAAwB;EAC1B;;EAEA;IACE,iCAAiC;EACnC;;EAEA;IACE,SAAS;EACX;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":[".menu_list {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    grid-template-rows: auto;\n  }\n\n  .menu_div{\n    /* max-width: 45rem !important; */\n  }\n\n  .footer_category{\n    left: -5%;\n  }\n\n  .slick_slider_top_reduce{\n    top: 24% !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
