import { createSlice } from "@reduxjs/toolkit";

export const ShippingChargesDataSlice = createSlice({
  name: "ShippingChargesData",
  initialState: {
    ShippingChargesData: null,
  },
  reducers: {
    setShippingChargesData: (state, action) => {
      state.ShippingChargesData = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setShippingChargesData } = ShippingChargesDataSlice.actions;

export default ShippingChargesDataSlice.reducer;

