
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const FlashNewsImage = (props) => {

    // const [opportunity, setOpportunity] = useState();
    // const [loadOpportunity, setLoadOpportunity] = useState(false);
    // console.log(props.flashNewsData);
    return (
        <>
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="custom-modal p-0"
            >
                <Modal.Header className="py-0" style={{ borderBottom: 'none' }}
                    closeButton
                >
                </Modal.Header>
                <Modal.Body className="p-0">
                    {props.flashNewsData && (
                        <div className="deal" style={{ backgroundImage: `url(${props.flashNewsData.image})`, borderRadius: '24px', backgroundSize: 'cover' }}>
                            {props?.flashNewsData?.title && (

                            <div className="d-flex align-items-center justify-content-center" style={{ backgroundColor: '#27272757' }}>
                                
                                    <div className="w-100 text-center">
                                        {props?.flashNewsData?.title && (
                                            <h4 className="mw-100 product-title">
                                                <a href="/" className="text-heading" style={{ color: 'white' }}>
                                                    {props.flashNewsData.title}
                                                </a>
                                            </h4>
                                        )}
                                        {props?.flashNewsData?.sub_title && (
                                            <h4 className="fw-bold">
                                                <a href="/" className="text-heading" style={{ color: '#a7ffac' }}>
                                                    {props.flashNewsData.sub_title}
                                                </a>
                                            </h4>
                                        )}
                                    </div>
                                
                            </div>
                            )}
                            {/* <div className="deal-top">
                            <h6 className="mb-10 text-brand-2">Deal of the Day</h6>
                        </div><div className="deal-content detail-info">
                            <h4 className="product-title">
                                <a href="/" className="text-heading">
                                    {props.flashNewsData.title}
                                </a>
                            </h4>
                            <div className="clearfix product-price-cover">
                                <div className="product-price primary-color float-left">
                                    <span className="current-price text-brand">$44.76</span>
                                    <span><span className="save-price font-md color3 ml-15">-39%% Off</span>
                                        <span className="old-price font-md ml-15">$74.00</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="deal-bottom">
                            <p className="mb-20">{props.flashNewsData.sub_title}</p>
                            <div className="deals-countdown pl-5" data-countdown="2023/10/04">
                                <span className="countdown-section">
                                    <span className="countdown-amount hover-up">13</span>
                                    <span className="countdown-period"> days </span>
                                </span>
                                <span className="countdown-section">
                                    <span className="countdown-amount hover-up">08</span>
                                    <span className="countdown-period"> hours </span>
                                </span>
                                <span className="countdown-section">
                                    <span className="countdown-amount hover-up">05</span>
                                    <span className="countdown-period"> mins </span>
                                </span>
                                <span className="countdown-section">
                                    <span className="countdown-amount hover-up">00</span>
                                    <span className="countdown-period"> sec </span>
                                </span>
                            </div>
                            <div className="product-detail-rating">
                                <div className="product-rate-cover text-end">
                                    <div className="product-rate d-inline-block">
                                        <div className="product-rating page_speed_121750838"></div>
                                    </div>
                                    <span className="font-small ml-5 text-muted">(4)</span>
                                </div>
                            </div>
                            <a href="/products" className="btn hover-up">Shop Now <i className="fi-rs-arrow-right"></i></a>
                        </div> */}
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default FlashNewsImage;
