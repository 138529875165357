// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.redeem_point_btn {
    color: #0098b8;
    font-weight: 700;
    cursor: pointer;
    font-size: 1.15rem;
}
.offline_msg{
    background: aliceblue;
    width: fit-content;
    padding: 1rem;
    border-radius: 1rem;
    position: fixed;
    bottom: 8%;
    left: 4%;
    z-index: 9999999999;
}
.offline_msg h5{
    color: red;
}`, "",{"version":3,"sources":["webpack://./src/assets/Css/checkout.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,UAAU;IACV,QAAQ;IACR,mBAAmB;AACvB;AACA;IACI,UAAU;AACd","sourcesContent":[".redeem_point_btn {\n    color: #0098b8;\n    font-weight: 700;\n    cursor: pointer;\n    font-size: 1.15rem;\n}\n.offline_msg{\n    background: aliceblue;\n    width: fit-content;\n    padding: 1rem;\n    border-radius: 1rem;\n    position: fixed;\n    bottom: 8%;\n    left: 4%;\n    z-index: 9999999999;\n}\n.offline_msg h5{\n    color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
