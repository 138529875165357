import { createSlice } from "@reduxjs/toolkit";

export const DhakaShippingZoneDataSlice = createSlice({
  name: "DhakaShippingZoneData",
  initialState: {
    DhakaShippingZoneData: null,
  },
  reducers: {
    setDhakaShippingZoneData: (state, action) => {
      state.DhakaShippingZoneData = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setDhakaShippingZoneData } = DhakaShippingZoneDataSlice.actions;

export default DhakaShippingZoneDataSlice.reducer;

