import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
// import CoinImage from '../assets/images/Icon/coin.png';
import CoinImage from '../assets/images/Icon/reward.png';
import GiftBg from '../assets/images/Icon/gifts_bg.jpg';
import '../assets/Css/rewards.css'
// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'

const Rewards = (props) => {
    const [isConfettiVisible, setIsConfettiVisible] = useState(true);
    const [loyaltyPoints, setLoyaltyPoints] = useState(0);
    const navigate = useNavigate();
    const ref = useRef(null);
    setTimeout(() => {
      setIsConfettiVisible(false);
     }, 4000);
    useEffect(() => {
      ref.current.continuousStart();
      
        axios
          .get("get-edit-customer/" + props.customer_id)
          .then(function (resp) {
            ref.current.complete();
            setLoyaltyPoints(resp.data.loyalty_points);
            if(resp.data.code===401 || resp.data.message === "Authorization token invalid, You cannot proceed"){
              navigate("/login?q=You%20Have%20To%20Login%20Again",{ replace: true });
            }
          })
          .catch((err) => {
            console.log(err);
            ref.current.complete();
          });
      
    }, [props.customer_id]);

 
    return (
        <div>
          {isConfettiVisible && (
                <Confetti
            width={window.innerWidth-20}
            height={window.innerHeight-20}
          />
          )}
            <LoadingBar
        color='#0098b8' 
        ref={ref}
        // progress={progress}
        // onLoaderFinished={() => setProgress(0)}
      />
            <div className="tab-content account dashboard-content pl-md-150  pt-md-55">
                  <div className="tab-pane fade active show" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                    <div className="card">
                      {/* <div>
                      <img className='img-fluid' src={GiftBg} style={{filter: 'blur(2px)'}} /> 
                      <center>
                      <div className='points_div text-center'>
                        <h6 className='col-12'>Points</h6>
                          <img className='img-fluid' src={CoinImage} width={22} height={22} /> 
                          {loyaltyPoints && loyaltyPoints}
                      </div>
                      </center>
                      </div> */}
                      <div className="reward_Card">
            <img className='img-fluid' src={GiftBg} style={{ filter: 'blur(1px)', width: '(100%)' }} />
            <center className="points_main_div">
              <div className='points_div text-center'>
                {/* <h6 className='col-12 points' style={{ fontSize: '1.5rem' }}>Points</h6> */}
                <img className='img-fluid coin_image' src={CoinImage} width={180} height={150} style={{ marginRight: '-0.5rem' }} />

                <div className="earned_coin">
                  <span >{loyaltyPoints ? loyaltyPoints : '0'}</span><br />
                  <div className="points text-center">
                    <span>Points</span>
                  </div>
                </div>

                <h4 className="discount_text">Earn coins and become a member to enjoy extra discount</h4>
              </div>
            </center>
          </div>
          <br/>
                      <div className='row justify-content-center reward_news'>
                        <div className='col-12 col-lg-7 mt-4'>
                          <h4>Keep Ordering to earn reward points &#9786;</h4>
                          <p>By claiming points you can avail discounts!! &#9786;
                            <br/>&#9786;Happy Shopping!! &#9786;
                          </p>
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

        </div>
    );
}

export default Rewards;