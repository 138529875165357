import { createSlice } from "@reduxjs/toolkit";

export const TopNewsDataSlice = createSlice({
  name: "TopNewsData",
  initialState: {
    TopNewsData: null,
  },
  reducers: {
    setTopNewsData: (state, action) => {
      state.TopNewsData = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setTopNewsData } = TopNewsDataSlice.actions;

export default TopNewsDataSlice.reducer;

