// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.daily_best_sales_product_card {

    position:absolute;
    bottom: 3%;
}
.popular_product_card {
    position:absolute;
    bottom: 3%;
}

.price_gap{
    padding-right: 100%;
}

.add_to_cart_gap{
    padding-left: 26%;
}
@media only screen and (max-width: 426px) {
    
.cate_slider_mobile{
 min-height: 142px;
}
}`, "",{"version":3,"sources":["webpack://./src/assets/Css/dailyBestSales.css"],"names":[],"mappings":"AAAA;;IAEI,iBAAiB;IACjB,UAAU;AACd;AACA;IACI,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;AACA;;AAEA;CACC,iBAAiB;AAClB;AACA","sourcesContent":[".daily_best_sales_product_card {\n\n    position:absolute;\n    bottom: 3%;\n}\n.popular_product_card {\n    position:absolute;\n    bottom: 3%;\n}\n\n.price_gap{\n    padding-right: 100%;\n}\n\n.add_to_cart_gap{\n    padding-left: 26%;\n}\n@media only screen and (max-width: 426px) {\n    \n.cate_slider_mobile{\n min-height: 142px;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
