import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import LoadingBar from 'react-top-loading-bar';
import { useDispatch } from 'react-redux';
import { setCustomerAddressData } from '../store/checkoutData/CustomerAddressData';

const AddressBook = (props) => {

    const [loading, setLoading] = useState(false);
    const ref = useRef(null);
    let navigate = useNavigate();
    const [addresses, setAddresses] = useState();
    const [defaultAddress, setDefaultAddress] = useState([]);
    const dispatch = useDispatch();
  useEffect(() => {
    if(!addresses){
      axios
        .get("get-customer-addresses/" + props.customer_id)
        .then(function (resp) {
          // console.log(resp.data);
          if(resp.data.data){
            setAddresses(resp.data.data);
            setDefaultAddress(resp.data.data.find(address => address.is_default == 1));
            dispatch(setCustomerAddressData(resp.data.data));
          }
          props.isLoaded(true);
        })
        .catch((err) => {
          console.log(err);
          props.isLoaded(true);
        });
    }
    // setAddresses(props.addresses);

  }, [props.customer_id]);
  // console.log(addresses);
  const handleDefaultAddressChange = (new_default_id) => {
    ref.current.continuousStart();
    axios.put("set-as-default-customer-address/" + props.customer_id + "/" + new_default_id)
    .then(function (resp) {
      setAddresses(null);
      ref.current.complete();
      if (resp.data.success) {
        Swal.fire({
          customClass: {
            icon: 'mt-4'
          },
          position: 'center',
          icon: 'success',
          title: resp.data.message,
          showConfirmButton: false,
          timer: 1000
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/customer/address",{ replace: true });
            
          }
        });
    
      }
    })
    .catch((err) => {
      console.log(err);
    });
 
  }

  const handleDelete = (id) => {
    Swal.fire({
      customClass: {
          icon: 'mt-4'
      },
      position: 'center',
      icon: 'question',
      title: 'Are You Sure?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
      if (result.isConfirmed) {
          
        ref.current.continuousStart();
    axios
    .get("delete-customer-address/"+id)
    .then(function (resp) {
      ref.current.complete();
      var data = resp.data;
      // console.log(data);
      if (data.success) {
        setAddresses(null);
          navigate("/customer/dashboard",{ replace: true });
      }
    })
    .catch((err) => {
      console.log(err);
    });
      }
  });
  };
    return (
        <div>
            <LoadingBar
        color='#0098b8' 
        ref={ref}
        // progress={progress}
        // onLoaderFinished={() => setProgress(0)}
      />
            <div className="tab-content account dashboard-content pl-md-150  pt-md-55">
                  <div className="tab-pane fade active show" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                    <div className="card">
                      <div className="row">
                             {addresses ?
                        addresses.map((address,index) => {
                             return (
                            <div key={index}>
                              <div id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab" className="tab-pane fade active show">
                                <div className="card">
                                  <div className="row">
                                    <div className="col-lg-12 dashboard-address-item  is-address-default ">
                                      <div className="card h-100 mb-3 mb-lg-0 mb-2">
                                        <div className="card-header">
                                          <h5 className="mb-0">
                                            {address.name} &nbsp;
                                            {address.is_default ===1 ? (
                                            <small className="badge bg-primary" style={{float:'right'}}>Default</small>
                                            ) : (
                                              <button style={{float:'right'}} className="btn" onClick={() => handleDefaultAddressChange(address.id)}>Make This Default</button>
                                            )}
                                          </h5>
                                        </div>{" "}
                                        <div className="card-body p-0">
                                          <address className='row'>
                                          <p className="col-6">Phone: {address.phone}</p>
                                          <p className="col-6">Email: {address.email}</p>
                                          <p className="col-6">Address: {address.address}</p>
                                          <p className="col-6">Area: {address.area && address.area}</p>
                                          <p className="col-6">City: {address.shipping_state && address.shipping_state.name}</p>
                                          <p className="col-6">Zip: {address.zip}</p>
                                          </address>{" "}
                                        </div>{" "}
                                        <div className="card-footer border-top-0">
                                          <div className="row">
                                            <div className="col-auto me-auto">
                                              <Link to={"/customer/edit-address/"+address.id}>Edit</Link>
                                            </div>{" "}
                                            <div className="col-auto">
                                              <Link
                                                to="#"
                                                className="text-danger btn-trigger-delete-address"
                                                onClick={(e) => handleDelete(address.id)}
                                              >
                                                Remove
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>{" "}
                                    <div className="col-12 m-2">
                                      <Link to="/customer/add-address" className="add-address">
                                        <i className="fa fa-plus"></i> <span>Add a new address</span>
                                      </Link>
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                            </div>
                            );
                            }) : (

                              <div>
                              <div className="col-12 mt-4">
                                <div className="alert alert-dark d-flex align-items-center" role="alert">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                                    viewBox="0 0 16 16"
                                    role="img"
                                    aria-label="Info:"
                                  >
                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                  </svg>
                                  <div>
                                   <Link to="/customer/add-address">You did not added any address here</Link>
                                  </div>
                                </div>
                              </div>
  
                              <div className="col-12 m-2">
                                <Link className="add-address" to="/customer/add-address">
                                  <i className="fa fa-plus"></i>
                                  <span>Add a new address</span>
                                </Link>
                              </div>
                            </div>
                            )}
                      </div>
                    </div>
                  </div>
                </div>

        </div>
    );
}

export default AddressBook;