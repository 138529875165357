import React, { useRef } from "react";
import { useEffect, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import Layout from "../layouts/Layout";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { useCart } from "react-use-cart";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { increment } from "../store/Counter";

const SearchResult = () => {
    const ref = useRef();
    const dispatch = useDispatch();
    const { addItem } = useCart();
    const [products, setProducts] = useState([]);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const productValue = urlParams.get('q');

    useEffect(() => {
        if (productValue?.length > 0) {
            axios.get(`products/search?product_name=${productValue}`)
                .then((resp) => {
                    if (resp?.data?.success) {
                        setProducts(resp?.data?.data?.data);
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
        }
        else {
            setProducts([]);
        }

    }, [productValue]);

    var customer_id;
    if (localStorage.getItem("user")) {
        var obj = JSON.parse(localStorage.getItem("user"));
        customer_id = obj.customer_id;
    }
    const addToWishlist = (pID) => {
        if (customer_id == null) {
            Navigate("/login?q=You%20Have%20To%20Login%20First%20For%20Wishlist", { replace: true });
            window.location.reload(false);
        }
        else {
            axios.get("add-to-wishlist/" + customer_id + "/" + pID)
                .then(resp => {

                    if (resp.data.success === true) {
                        dispatch(increment());
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Product added to wishlist',
                            showConfirmButton: false,
                            timer: 800
                        });
                    }
                    else if (resp.data.success === false) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: resp.data.message
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }
    const { UserData } = useSelector((state) => state.UserData);
    const addToCart = (pID, qty) => {
        if (customer_id) {
            axios.post("add-to-cart/" + customer_id, {
                customer_id: customer_id,
                product_id: pID,
                quantity_of_orders: qty
            })
                .then(resp => {
                    // console.log(resp.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }
    return (
        <div>
            <LoadingBar
                color='#0098b8'
                ref={ref}
            // progress={progress}
            // onLoaderFinished={() => setProgress(0)}
            />
            <Layout>
                <main className="main" id="main-section">
                    <div className="page-header breadcrumb-wrap">
                        <div className="breadcrumb">
                            <Link to="/" itemProp="item" title="Home">
                                Home
                                <meta itemProp="name" content="Home" />
                                <meta itemProp="position" content="1" />
                            </Link>
                            <span></span> Search
                            <meta itemProp="name" content="Search" />
                            <meta itemProp="position" content="2" />
                        </div>
                    </div>

                    <div className="px-2 pt-1 pb-4">
                        <p className='mb-2'>{products?.length}+ results for your search</p>
                        <h2 className=''>&apos;{productValue}&apos;</h2>
                    </div>
                    <div className="row product-grid-4 ">
                        {products ?
                            products.map((product, index) => {
                                if (product != null) {
                                    // console.log(isNaN('null'));
                                    return (
                                        <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5" key={index}>
                                            <div
                                                data-wow-delay="0.1s"
                                                className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                                                style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                                            >
                                                <div className="product-img-action-wrap">
                                                    <div className="product-img product-img-zoom">
                                                        <Link to={'/products/' + product.slug}>
                                                            {product.image && (

                                                                <>
                                                                    {/* <img
                            src={product.image.medium}
                            alt="not found img"
                            className="default-img"
                          /> */}
                                                                    <LazyLoadImage className="default-img" alt="not found img" effect="blur" src={product.image.medium} />
                                                                </>
                                                            )}
                                                        </Link>
                                                    </div>
                                                    <div className="product-action-1">

                                                        <a href="#" aria-label="Add To Wishlist" to="" data-url="#" className="action-btn hover-up js-add-to-wishlist-button"
                                                            onClick={() =>
                                                                addToWishlist(product.id)
                                                            }>
                                                            <i className="fi-rs-heart"></i>
                                                        </a>

                                                    </div>
                                                    {/* discount percentage */}
                                                    {isNaN(product.discount) && (
                                                        <div className="product-badges product-badges-position product-badges-mrg">
                                                            <span className="hot">{product.discount} Off</span>
                                                        </div>
                                                    )}
                                                    {product.stock <= 0 && (
                                                        <div className="product-badges product-badges-position product-badges-mrg otstck">
                                                            <span className="otostck">Out Of Stock!</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="product-content-wrap">
                                                    <h2>
                                                        <Link to={'/products/' + product.slug} title={product.name}>{product.name}</Link>
                                                    </h2>
                                                    {/* <div className="product-rate-cover">
                      <div className="product-rate d-inline-block">
                        <div className="product-rating" style={{ width: "80%" }}></div>
                      </div>
                      <span className="font-small ml-5 text-muted"> (3)</span>
                    </div> */}

                                                    <div className="product-card-bottom row">
                                                        <div className="col-6 ps-1 col-md-6 text-center">
                                                            {isNaN(product.discount) ? (
                                                                <div className="product-price">
                                                                    <span>{product.formatted_final_product_price}</span>
                                                                    <span className="old-price">{product.formatted_regular_price}</span>
                                                                </div>
                                                            ) : (
                                                                <div className="product-price text-start">
                                                                    <span>{product.formatted_final_product_price}</span>
                                                                    {/* <span className="old-price">{product.formatted_regular_price}</span> */}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-6 pe-0 col-md-6 text-center">
                                                            <div
                                                                className="add-cart"
                                                            >
                                                                <a href="#" aria-label="Add To Cart" data-id="2" data-url="#" to="" className="action-btn add-to-cart-button add" onClick={() => {
                                                                    if (product.stock !== null && product.stock > 0) {
                                                                        // if(inCart(product.id)){
                                                                        //   Swal.fire({
                                                                        //     position: 'center',
                                                                        //     icon: 'warning',
                                                                        //     title: 'The product is already in the cart'
                                                                        //   });
                                                                        // }
                                                                        // else{
                                                                        addItem({
                                                                            id: product.id,
                                                                            product_id: product.id,
                                                                            slug: product.slug,
                                                                            name: product.name,
                                                                            price: Number(product.final_product_price).toFixed(2),
                                                                            quantity: 1,
                                                                            image: product.image.small,
                                                                            stock: product.stock,
                                                                            is_enable_point: product.is_enable_point,
                                                                        });
                                                                        if (UserData && UserData.customer_id) {
                                                                            addToCart(product.id, 1);
                                                                        }
                                                                        Swal.fire({
                                                                            position: 'top-end',
                                                                            icon: 'success',
                                                                            title: 'Product added to cart',
                                                                            showConfirmButton: false,
                                                                            timer: 800
                                                                        });
                                                                        // }
                                                                    }
                                                                    else {
                                                                        Swal.fire({
                                                                            position: 'top-end',
                                                                            icon: 'warning',
                                                                            title: 'Product is out of stock!'
                                                                        });
                                                                    }
                                                                }
                                                                }>
                                                                    <i className="fi-rs-shopping-cart mr-5"></i>Add
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            }) : (
                                <>
                                    <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
                                        <div
                                            data-wow-delay="0.1s"
                                            className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                                            style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                                        >
                                            <Skeleton className="product_skelton_image" /><br />
                                            <center><Skeleton className="product_skelton_title" /></center><br />
                                            <center>
                                                <div className="row">
                                                    <div className="col-6 ps-0">
                                                        <Skeleton className="product_skelton_price" inline={true} />
                                                    </div>
                                                    <div className="col-6 pe-0">
                                                        <Skeleton className="product_skelton_add_btn" inline={true} />
                                                    </div>
                                                </div>
                                            </center>
                                        </div>

                                    </div>

                                    <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
                                        <div
                                            data-wow-delay="0.1s"
                                            className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                                            style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                                        >
                                            <Skeleton className="product_skelton_image" /><br />
                                            <center><Skeleton className="product_skelton_title" /></center><br />
                                            <center>
                                                <div className="row">
                                                    <div className="col-6 ps-0">
                                                        <Skeleton className="product_skelton_price" inline={true} />
                                                    </div>
                                                    <div className="col-6 pe-0">
                                                        <Skeleton className="product_skelton_add_btn" inline={true} />
                                                    </div>
                                                </div>
                                            </center>
                                        </div>
                                    </div>

                                    <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
                                        <div
                                            data-wow-delay="0.1s"
                                            className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                                            style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                                        >
                                            <Skeleton className="product_skelton_image" /><br />
                                            <center><Skeleton className="product_skelton_title" /></center><br />
                                            <center>
                                                <div className="row">
                                                    <div className="col-6 ps-0">
                                                        <Skeleton className="product_skelton_price" inline={true} />
                                                    </div>
                                                    <div className="col-6 pe-0">
                                                        <Skeleton className="product_skelton_add_btn" inline={true} />
                                                    </div>
                                                </div>
                                            </center>
                                        </div>
                                    </div>

                                    <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
                                        <div
                                            data-wow-delay="0.1s"
                                            className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                                            style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                                        >
                                            <Skeleton className="product_skelton_image" /><br />
                                            <center><Skeleton className="product_skelton_title" /></center><br />
                                            <center>
                                                <div className="row">
                                                    <div className="col-6 ps-0">
                                                        <Skeleton className="product_skelton_price" inline={true} />
                                                    </div>
                                                    <div className="col-6 pe-0">
                                                        <Skeleton className="product_skelton_add_btn" inline={true} />
                                                    </div>
                                                </div>
                                            </center>
                                        </div>
                                    </div>
                                    <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
                                        <div
                                            data-wow-delay="0.1s"
                                            className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                                            style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                                        >
                                            <Skeleton className="product_skelton_image" /><br />
                                            <center><Skeleton className="product_skelton_title" /></center><br />
                                            <center>
                                                <div className="row">
                                                    <div className="col-6 ps-0">
                                                        <Skeleton className="product_skelton_price" inline={true} />
                                                    </div>
                                                    <div className="col-6 pe-0">
                                                        <Skeleton className="product_skelton_add_btn" inline={true} />
                                                    </div>
                                                </div>
                                            </center>
                                        </div>
                                    </div>
                                    <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
                                        <div
                                            data-wow-delay="0.1s"
                                            className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                                            style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                                        >
                                            <Skeleton className="product_skelton_image" /><br />
                                            <center><Skeleton className="product_skelton_title" /></center><br />
                                            <center>
                                                <div className="row">
                                                    <div className="col-6 ps-0">
                                                        <Skeleton className="product_skelton_price" inline={true} />
                                                    </div>
                                                    <div className="col-6 pe-0">
                                                        <Skeleton className="product_skelton_add_btn" inline={true} />
                                                    </div>
                                                </div>
                                            </center>
                                        </div>
                                    </div>
                                    <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
                                        <div
                                            data-wow-delay="0.1s"
                                            className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                                            style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                                        >
                                            <Skeleton className="product_skelton_image" /><br />
                                            <center><Skeleton className="product_skelton_title" /></center><br />
                                            <center>
                                                <div className="row">
                                                    <div className="col-6 ps-0">
                                                        <Skeleton className="product_skelton_price" inline={true} />
                                                    </div>
                                                    <div className="col-6 pe-0">
                                                        <Skeleton className="product_skelton_add_btn" inline={true} />
                                                    </div>
                                                </div>
                                            </center>
                                        </div>
                                    </div>
                                    <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
                                        <div
                                            data-wow-delay="0.1s"
                                            className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                                            style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                                        >
                                            <Skeleton className="product_skelton_image" /><br />
                                            <center><Skeleton className="product_skelton_title" /></center><br />
                                            <center>
                                                <div className="row">
                                                    <div className="col-6 ps-0">
                                                        <Skeleton className="product_skelton_price" inline={true} />
                                                    </div>
                                                    <div className="col-6 pe-0">
                                                        <Skeleton className="product_skelton_add_btn" inline={true} />
                                                    </div>
                                                </div>
                                            </center>
                                        </div>
                                    </div>
                                </>
                            )}
                    </div>
                </main>
            </Layout>
        </div>
    );
};

export default SearchResult;