import React from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCart } from "react-use-cart";
import '../assets/Css/dailyBestSales.css';
import '../assets/Css/product_skelton.css';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from "react-redux";
import { increment } from "../store/Counter";
import Skeleton from "react-loading-skeleton";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ProductBoxes = (props) => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const { addItem } = useCart();
  // const { inCart } = useCart();
  const [products, setProducts] = useState();
  const pType = props.productType;
  const { PopularProductData } = useSelector((state) => state.PopularProductData);
  const [paginateInfo, setPaginateInfo] = useState();
  const [page, setPage] = useState(1);
  const [show, setShow] = useState('12');
  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("");
  const [query, setQuery] = useState('');
  // console.log(PopularProductData);
  // console.log(paginateInfo);
  useEffect(() => {
    setQuery('?platform=web&page=' + page + '&limit=' + show + '&sort_by=' + sortBy.slice(0, -2) + '&sort_type=' + sortType);
  }, [show, sortBy, sortType, page]);

  useEffect(() => {
    if (pType && query) {
      //   if (pType === 'get-products') {
      //   setLoading(true);
      // }
      if (pType === 'popular') {

        setProducts(PopularProductData);
      }
      else {
        // console.log(query);
        setProducts(false);
        axios.get("" + pType + query)
          .then(resp => {
            if (pType === 'get-products') {
              // console.log(resp.data);
              window.scrollTo(0, 300)
              setPaginateInfo(resp.data.data.meta);
              setProducts(resp.data.data.data);
              setLoading(false);
            }
            else if (pType === 'dealsday') {

              setProducts(resp.data.data);
              // console.log(resp.data.data);
            }
          });
      }

    }
  }, [PopularProductData, pType, query]);

  var customer_id;
  if (localStorage.getItem("user")) {
    var obj = JSON.parse(localStorage.getItem("user"));
    customer_id = obj.customer_id;
  }
  const addToWishlist = (pID) => {
    if (customer_id == null) {
      navigate("/login?q=You%20Have%20To%20Login%20First%20For%20Wishlist", { replace: true });
      window.location.reload(false);
    }
    else {
      axios.get("add-to-wishlist/" + customer_id + "/" + pID)
        .then(resp => {

          if (resp.data.success === true) {
            dispatch(increment());
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Product added to wishlist',
              showConfirmButton: false,
              timer: 800
            });
          }
          else if (resp.data.success === false) {
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: resp.data.message
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  const { UserData } = useSelector((state) => state.UserData);
  // console.log(UserData);  
  const addToCart = (pID, qty) => {
    if (customer_id) {
      axios.post("add-to-cart/" + customer_id, {
        customer_id: customer_id,
        product_id: pID,
        quantity_of_orders: qty
      })
        .then(resp => {
          // console.log(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  // console.log(products);
  return (
    <>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      {pType === 'get-products' && (
        <div className='row d-flex flex-row-reverse mb-4'>
          <div className='col-12 col-md-8 col-lg-4'>
            <div className='row justify-content-end'>
              <div className='col-4 col-md-6'> <select className="form-control"
                name="show" onChange={(e) => { setShow(e.target.value); }}>
                <option value={''}>Show</option>
                <option value={'12'}>12</option>
                <option value={'24'}>24</option>
                <option value={'36'}>36</option>
                <option value={'48'}>48</option>
                <option value={'60'}>60</option>
              </select>
              </div>
              <div className='col-6'>
                <select className="form-control"
                  name="city"
                  value={sortBy} onChange={(e) => { setSortBy(e.target.value); setSortType(e.target.childNodes[e.target.selectedIndex].getAttribute('type')); }}>
                  <option value={''} type={''}>Sort by</option>
                  <option value={'id-n'} type={'desc'}>Newest</option>
                  <option value={'id-o'} type={'asc'}>Oldest</option>
                  <option value={'product_name-a'} type={'asc'}>Name: (A-Z)</option>
                  <option value={'product_name-d'} type={'desc'}>Name: (Z-A)</option>
                  <option value={'attribute_final_price-l'} type={'asc'}>Price:low to high</option>
                  <option value={'attribute_final_price-h'} type={'desc'}>Price:high to low</option>
                  {/* <option value={'rating'} type={'desc'}>Most Rated</option> */}
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row product-grid-4 ">
        {products ?
          products.map((product, index) => {
            if (product != null) {
              // console.log(isNaN('null'));
              return (
                <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5" key={index}>
                  <div
                    data-wow-delay="0.1s"
                    className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                    style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                  >
                    <div className="product-img-action-wrap">
                      <div className="product-img product-img-zoom">
                        <Link to={'/products/' + product.slug}>
                          {product.image && (

                            <>
                              {/* <img
                            src={product.image.medium}
                            alt="not found img"
                            className="default-img"
                          /> */}
                              <LazyLoadImage className="default-img" alt="not found img" effect="blur" src={product.image.medium} />
                            </>
                          )}
                        </Link>
                      </div>
                      <div className="product-action-1">

                        <Link aria-label="Add To Wishlist" to="" data-url="#" className="action-btn hover-up js-add-to-wishlist-button"
                          onClick={() =>
                            addToWishlist(product.id)
                          }>
                          <i className="fi-rs-heart"></i>
                        </Link>

                      </div>
                      {/* discount percentage */}
                      {isNaN(product.discount) && (
                        <div className="product-badges product-badges-position product-badges-mrg">
                          <span className="hot">{product.discount} Off</span>
                        </div>
                      )}
                      {product.stock <= 0 && (
                        <div className="product-badges product-badges-position product-badges-mrg otstck">
                          <span className="otostck">Out Of Stock!</span>
                        </div>
                      )}
                    </div>
                    <div className="product-content-wrap">
                      <h2>
                        <Link to={'/products/' + product.slug} title={product.name}>{product.name}</Link>
                      </h2>
                      {/* <div className="product-rate-cover">
                      <div className="product-rate d-inline-block">
                        <div className="product-rating" style={{ width: "80%" }}></div>
                      </div>
                      <span className="font-small ml-5 text-muted"> (3)</span>
                    </div> */}

                      <div className="product-card-bottom row">
                        <div className="col-6 ps-1 col-md-6 text-center">
                          {isNaN(product.discount) ? (
                            <div className="product-price">
                              <span>{product.formatted_final_product_price}</span>
                              <span className="old-price">{product.formatted_regular_price}</span>
                            </div>
                          ) : (
                            <div className="product-price text-start">
                              <span>{product.formatted_final_product_price}</span>
                              {/* <span className="old-price">{product.formatted_regular_price}</span> */}
                            </div>
                          )}
                        </div>
                        <div className="col-6 pe-0 col-md-6 text-center">
                          <div
                            className="add-cart"
                          >
                            <Link aria-label="Add To Cart" data-id="2" data-url="#" to="" className="action-btn add-to-cart-button add" onClick={() => {
                              if (product.stock !== null && product.stock > 0) {
                                // if(inCart(product.id)){
                                //   Swal.fire({
                                //     position: 'center',
                                //     icon: 'warning',
                                //     title: 'The product is already in the cart'
                                //   });
                                // }
                                // else{
                                addItem({
                                  id: product.id,
                                  product_id: product.id,
                                  slug: product.slug,
                                  name: product.name,
                                  price: Number(product.final_product_price).toFixed(2),
                                  quantity: 1,
                                  image: product.image.small,
                                  stock: product.stock,
                                  is_enable_point: product.is_enable_point,
                                });
                                if (UserData && UserData.customer_id) {
                                  addToCart(product.id, 1);
                                }
                                Swal.fire({
                                  position: 'top-end',
                                  icon: 'success',
                                  title: 'Product added to cart',
                                  showConfirmButton: false,
                                  timer: 800
                                });
                                // }
                              }
                              else {
                                Swal.fire({
                                  position: 'top-end',
                                  icon: 'warning',
                                  title: 'Product is out of stock!'
                                });
                              }
                            }
                            }>
                              <i className="fi-rs-shopping-cart mr-5"></i>Add
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          }) : (
            <>
              <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
                <div
                  data-wow-delay="0.1s"
                  className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                  style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                >
                  <Skeleton className="product_skelton_image" /><br />
                  <center><Skeleton className="product_skelton_title" /></center><br />
                  <center>
                    <div className="row">
                      <div className="col-6 ps-0">
                        <Skeleton className="product_skelton_price" inline={true} />
                      </div>
                      <div className="col-6 pe-0">
                        <Skeleton className="product_skelton_add_btn" inline={true} />
                      </div>
                    </div>
                  </center>
                </div>

              </div>

              <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
                <div
                  data-wow-delay="0.1s"
                  className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                  style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                >
                  <Skeleton className="product_skelton_image" /><br />
                  <center><Skeleton className="product_skelton_title" /></center><br />
                  <center>
                    <div className="row">
                      <div className="col-6 ps-0">
                        <Skeleton className="product_skelton_price" inline={true} />
                      </div>
                      <div className="col-6 pe-0">
                        <Skeleton className="product_skelton_add_btn" inline={true} />
                      </div>
                    </div>
                  </center>
                </div>
              </div>

              <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
                <div
                  data-wow-delay="0.1s"
                  className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                  style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                >
                  <Skeleton className="product_skelton_image" /><br />
                  <center><Skeleton className="product_skelton_title" /></center><br />
                  <center>
                    <div className="row">
                      <div className="col-6 ps-0">
                        <Skeleton className="product_skelton_price" inline={true} />
                      </div>
                      <div className="col-6 pe-0">
                        <Skeleton className="product_skelton_add_btn" inline={true} />
                      </div>
                    </div>
                  </center>
                </div>
              </div>

              <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
                <div
                  data-wow-delay="0.1s"
                  className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                  style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                >
                  <Skeleton className="product_skelton_image" /><br />
                  <center><Skeleton className="product_skelton_title" /></center><br />
                  <center>
                    <div className="row">
                      <div className="col-6 ps-0">
                        <Skeleton className="product_skelton_price" inline={true} />
                      </div>
                      <div className="col-6 pe-0">
                        <Skeleton className="product_skelton_add_btn" inline={true} />
                      </div>
                    </div>
                  </center>
                </div>
              </div>
              <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
                <div
                  data-wow-delay="0.1s"
                  className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                  style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                >
                  <Skeleton className="product_skelton_image" /><br />
                  <center><Skeleton className="product_skelton_title" /></center><br />
                  <center>
                    <div className="row">
                      <div className="col-6 ps-0">
                        <Skeleton className="product_skelton_price" inline={true} />
                      </div>
                      <div className="col-6 pe-0">
                        <Skeleton className="product_skelton_add_btn" inline={true} />
                      </div>
                    </div>
                  </center>
                </div>
              </div>
              <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
                <div
                  data-wow-delay="0.1s"
                  className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                  style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                >
                  <Skeleton className="product_skelton_image" /><br />
                  <center><Skeleton className="product_skelton_title" /></center><br />
                  <center>
                    <div className="row">
                      <div className="col-6 ps-0">
                        <Skeleton className="product_skelton_price" inline={true} />
                      </div>
                      <div className="col-6 pe-0">
                        <Skeleton className="product_skelton_add_btn" inline={true} />
                      </div>
                    </div>
                  </center>
                </div>
              </div>
              <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
                <div
                  data-wow-delay="0.1s"
                  className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                  style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                >
                  <Skeleton className="product_skelton_image" /><br />
                  <center><Skeleton className="product_skelton_title" /></center><br />
                  <center>
                    <div className="row">
                      <div className="col-6 ps-0">
                        <Skeleton className="product_skelton_price" inline={true} />
                      </div>
                      <div className="col-6 pe-0">
                        <Skeleton className="product_skelton_add_btn" inline={true} />
                      </div>
                    </div>
                  </center>
                </div>
              </div>
              <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
                <div
                  data-wow-delay="0.1s"
                  className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                  style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                >
                  <Skeleton className="product_skelton_image" /><br />
                  <center><Skeleton className="product_skelton_title" /></center><br />
                  <center>
                    <div className="row">
                      <div className="col-6 ps-0">
                        <Skeleton className="product_skelton_price" inline={true} />
                      </div>
                      <div className="col-6 pe-0">
                        <Skeleton className="product_skelton_add_btn" inline={true} />
                      </div>
                    </div>
                  </center>
                </div>
              </div>
            </>
          )}
      </div>
      {/* {pType === 'get-products' && (
        <>
          <div className="pagination-area ps-3 mt-20 mb-20 pagination-page">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-start">
                {paginateInfo && (paginateInfo.links.map((link, index) => {
                  return (
                    <li key={index} className={link.active ? "page-item active" : "page-item"}>
                      {link.label === '&laquo; Previous' ? (
                        <button className="page-link" onClick={() => {
                          setProducts(null);
                          if (link.label === '&laquo; Previous') {
                            setPage(paginateInfo?.current_page - 1);
                          }
                          else { setPage(paginateInfo.current_page); }
                        }}>
                          {link.label === '&laquo; Previous' ? (<i className="fi-rs-arrow-small-left "></i>) : ''}
                        </button>
                      ) :
                        (
                          <>
                            <button className={`page-link ${link.label === page ? 'active' : ''}`} onClick={() => {
                              setProducts(null);
                              setPage(link.label);
                              if (link.label === 'Next &raquo;') {
                                setProducts(null);
                                if (paginateInfo?.last_page > page) {
                                  setPage(paginateInfo?.current_page + 1);
                                }
                                else { setPage(paginateInfo.current_page); }
                              }
                            }}>
                              {link.label === 'Next &raquo;' ? (<i className="fi-rs-arrow-small-right "></i>)
                                :
                                link.label}
                            </button>
                          </>
                        )}
                    </li>
                  )
                }))
                }
              </ul>
            </nav>
          </div>
        </>
      )} */}
      {pType === 'get-products' && (
        <div className="pagination-area ps-3 mt-20 mb-20 pagination-page">
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-start">
              {paginateInfo?.links.map((link, index) => (
                <li key={index} className={`page-item ${link.active ? "active" : ""}`}>
                  <button className="page-link" onClick={() => {
                    setProducts(null);
                    if (link.label === '&laquo; Previous') {
                      setPage(paginateInfo.current_page - 1);
                    } else if (link.label === 'Next &raquo;') {
                      if (paginateInfo.last_page > page) {
                        setPage(paginateInfo.current_page + 1);
                      }
                    } else {
                      setPage(link.label);
                    }
                  }}>
                    {link.label === '&laquo; Previous' && <i className="fi-rs-arrow-small-left"></i>}
                    {link.label === 'Next &raquo;' && <i className="fi-rs-arrow-small-right"></i>}
                    {link.label !== '&laquo; Previous' && link.label !== 'Next &raquo;' && link.label}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      )}
    </>
  );
};
export default ProductBoxes;
