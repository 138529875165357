import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../layouts/Layout";
import Parser from 'html-react-parser';
import Swal from "sweetalert2";
import LoadingBar from "react-top-loading-bar";

const OtpNewPassword = () => {
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();
    const { customer_id } = useParams();

    const ref = useRef();
    useEffect(() => {
        ref.current.continuousStart();
        setTimeout(() => {
            ref.current.complete();
        }, 200);
        
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        document.title = "Ultimate Organic Life | Enter OTP & New Password";
    }, []);

    // const [error, setError] = useState();
    let [otp, setOTP] = useState("");

    const [errorList, setError] = useState([]);
    let [password, setPassword] = useState("");
    let [passwordConfirm, setPasswordConfirm] = useState("");
    const [confirmPassErr, setConfirmPassErr] = useState("");
    const confirmPass = () => {
        if (password === passwordConfirm) {
            setConfirmPassErr('');
        }
        else {

            setConfirmPassErr('Password and confirm password does not match!');
        }
    }

    const resetPassword = () => {
        ref.current.continuousStart();
        if (!otp) {
            
      ref.current.complete();
            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'OTP Required!',
                // showConfirmButton: false,
                // timer: 2000
            });
        }
        else if (!confirmPassErr=="") {
            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Password and confirm password does not match!',
                // showConfirmButton: false,
                // timer: 2000
            });
        }
        else {
            axios
                .post("verify-reset-customer-password/" + customer_id, { otp: otp, password: passwordConfirm })
                .then(function (resp) {
                    // console.log(resp.data);
                    if (resp.data.success) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: resp.data.message,
                            // showConfirmButton: false,
                            // timer: 2000
                        }).then(function (response) {
                            if (response.isConfirmed) {
                                navigate("/login");
                                window.location.reload(false);
                            }
                        });
                    }
                    else {
                        Swal.fire({
                            position: 'center',
                            icon: 'warning',
                            title: 'OTP not matched or already used',
                            // showConfirmButton: false,
                            // timer: 2000
                        });
                        setError(resp.data.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setError(err.response.data.errors);
                });
        }
    }
    return (
        <div>
            <LoadingBar
                color='#0098b8' 
                ref={ref}
                // progress={progress}
                // onLoaderFinished={() => setProgress(0)}
            />
            <Layout>
                <div className="page-header breadcrumb-wrap">
                    <div className="breadcrumb">
                        <Link to="/" itemProp="item" title="Home">
                            Home
                        </Link>
                        <span></span> About Us
                        <span></span>
                    </div>
                </div>
                <br />
                <div className="row justify-content-center">
                    <div className="col-6 pt-1 pb-4">
                        <div className="login_wrap widget-taber-content p-30 background-white border-radius-10">
                            <div className="padding_eight_all bg-white">
                                <div className="heading_s1 mb-20">
                                    <h3 className="mb-30">OTP Verify & New Password</h3>
                                </div>
                                <div className="form__content">
                                    <div className="form-group">
                                        <label htmlFor="otp" className="required">OTP</label>
                                        <input name="otp" id="otp" type="text" value={otp} onChange={(e) => { setOTP(e.target.value) }} placeholder="OTP" className="form-control" required />
                                    </div>
                                    <div className="form-group ">
                                        <label className="required" htmlFor="password">
                                            New password:
                                        </label>
                                        <input type="password" className="form-control square" name="password" id="password" placeholder="New password" value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            onBlur={() => confirmPass()} />
                                        <span className='text-danger'>{errorList.password}</span>
                                    </div>
                                    <div className="form-group ">
                                        <label className="required" htmlFor="password_confirmation">
                                            Password confirmation:
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control square"
                                            name="password_confirmation"
                                            id="password_confirmation"
                                            placeholder="Password confirmation"
                                            value={passwordConfirm}
                                            onChange={(e) => { setPasswordConfirm(e.target.value); }}
                                            onKeyUp={() => confirmPass()}
                                        />
                                        {errorList.password_confirmation ? (
                                            <span className='text-danger'>{errorList.password_confirmation}</span>
                                        ) : (
                                            <span className='text-danger'>{confirmPassErr}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-fill-out btn-block hover-up" onClick={() => { resetPassword(); }}>Reset Password</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Layout>
        </div>
    );
};

export default OtpNewPassword;
