import React from "react";
// import { IncreaseQuantity, DecreaseQuantity, DeleteCart, GetNumberCart } from "../Redux/actions";
import { connect, useSelector } from "react-redux";
import { useState } from "react";
import { Link } from "react-router-dom";
import { CartProvider, useCart } from "react-use-cart";
import Swal from "sweetalert2";
import axios from "axios";

const CartPopup = (prop) => {
  
  const {
    isEmpty,
    totalUniqueItems,
    items,
    updateItemQuantity,
    removeItem,
  } = useCart();

  let totalPrice = 0;

    items.forEach(function (item) {
        totalPrice += item.quantity * item.price;
    });
    const { UserData } = useSelector((state) => state.UserData);
    const removeFromCart = (cartId) => {
      axios.get("delete-cart-product/" + cartId)
        .then(resp => {
          // console.log(resp.data);
        }).catch(err => {
            console.log(err);
        });
    }
  return (
    <div>
      <div className="cart-dropdown-wrap cart-dropdown-hm2 cart-dropdown-panel">
        {items.map((item, key) => {
          // console.log("list total : " + key);
          return (
            <div key={key}>
              <ul key={key}>
                <li key={key}>
                  <div className="shopping-cart-img">
                    <a href="#">
                      <img alt="Chobani Complete Vanilla Greek" src={item.image} />
                    </a>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link to={"/products/" + item.slug}>{item.name} </Link>
                    </h4>
                    <h3>
                    {" "}
                      <span className="d-inline-block">{Number(item.price*item.quantity).toFixed(2)} ৳</span> <small></small>
                    </h3>
                    <p className="mb-0"></p>
                  </div>
                  <div className="shopping-cart-delete" onClick={() => {
                          removeItem(item.id);
                          if(UserData && UserData.customer_id){
                            removeFromCart(item.id);
                          }
                          Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'Product removed form the cart',
                            showConfirmButton: false,
                            timer: 800
                          });
                          }}>
                    <a href="#" className="remove-cart-item">
                      <i className="fi-rs-cross-small"></i>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          );
        })}
        <div className="shopping-cart-footer">
          <div className="shopping-cart-total">
            <h5>
              <strong className="d-inline-block">Sub Total:</strong>
                    <span>{Number(totalPrice).toFixed(2)} ৳</span>
            </h5>
            <div className="clearfix"></div>
            <h5>
              <strong className="d-inline-block">Tax:</strong> <span>-- ৳</span>
            </h5>
            <div className="clearfix"></div>
            <h4>
            </h4>
          </div>
          <div className="shopping-cart-button">
            <Link className="btn" to="/cart">View cart</Link>
            <Link to="/checkout">Checkout</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = (state) => {
//   // console.log('state: '+state._todoProduct)
//   return {
//     items: state._todoProduct,
//   };
// };

// export default connect(mapStateToProps, { IncreaseQuantity, DecreaseQuantity, DeleteCart })(CartPopup);
export default CartPopup;
