import { createSlice } from "@reduxjs/toolkit";

export const CategoryDataSlice = createSlice({
  name: "CategoryData",
  initialState: {
    CategoryData: [],
  },
  reducers: {
    setCategoryData: (state, action) => {
      state.CategoryData = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setCategoryData } = CategoryDataSlice.actions;

export default CategoryDataSlice.reducer;

